<script>
    import { onMenu } from "./../stores/stores";

    let onMenuValue;

    onMenu.subscribe((val) => {
        onMenuValue = val;
    });

    const toggleMenuState = () => {
        onMenu.update((n) => !n);
    }


</script>

<div class="overlay" class:on={onMenuValue} on:click={toggleMenuState}/>

<style type="text/scss">

    @import '../rules/mixins.scss';

    .overlay {
        position: fixed;
        width: 100%;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0);
        pointer-events: none;
        z-index: 2;
        transition:all 0.2s ease 0s;
        &.on{
            pointer-events: visible;
            background-color: rgba(0, 0, 0, 0.6);
            @include mode('desktop'){
                pointer-events: none;
                background-color: transparent;
            }
        }
    }
</style>
