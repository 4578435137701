<script>
    import { each } from "svelte/internal";

    export let text = "";
    let ended = false;
    let currentId = Math.floor(Math.random() * 100);

    /*------------------------------*"
    - FUNCTIONNAL
    \*------------------------------*/

    const getText = () => {
        return text.split("");
    };
</script>

<h1 class="title" data-aos data-aos-offset="100" data-aos-id={`titleB${currentId}`}>
    {#each getText() as letter, key}
        <span class="letter" style:--delay={`${key * 0.05}s`}>{letter}</span>
    {/each}
</h1>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";

    h1.title {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        // font-size: 75px;
        font-family: Oswald;
        color: black;
        position: relative;
        font-size: 28px;
        font-weight: bold;
        margin-bottom: 10px;
        text-transform: uppercase;
        @include mode("desktop") {
            font-size: 40px;
        }

        span.letter {
            opacity: 0;
            transform: translateX(70px) scaleY(1.6);
            // animation: letter 0.6s ease 0s forwards !important;
        }

        :global(&.aos-animate .letter) {
            // transition: all 0.3s ease var(--delay, 0s) !important;
            // opacity: 1;
            // transform: translateX(0px);
            animation: letter 0.6s ease var(--delay, 0s) forwards !important;
            @keyframes letter {
                0% {
                    transform: translateX(70px) scaleY(1.6);
                }
                50% {
                    transform: translateX(0px) scaleY(1);
                    opacity: 1;
                    color: $color-red;
                }
                65% {
                    color: black;
                }
                80% {
                    color: $color-red;
                }
                90% {
                    color: black;
                }
                100% {
                    color: $color-red;
                    transform: translateX(0px) scaleY(1);
                    opacity: 1;
                }
            }
        }
    }
</style>
