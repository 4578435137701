<script>
    export let important = false;
</script>

<span class="bold" class:important={important}>
    <slot></slot>
</span>

<style type="text/scss">
    @import '../rules/colors.scss';
    @import '../rules/mixins.scss';

    .bold{
        font-family: Anton;
        color:$color-red;
        font-weight: 400;

        &.important{
            font-size: 20px;
        }
    }
</style>