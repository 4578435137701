<script>

    import { onPlayer } from "../stores/stores";
    import VideoItem from "../common/VideoItem.svelte";

    let onPlayerValue;

    onPlayer.subscribe((val) => {
        onPlayerValue = val;
    });

    const updatePlayerValue = (value = "") => {
        onPlayer.update((n) => value);
    };
</script>

<div class="trailer">

    <div
        class="video2"
        on:click={() => {
            let y = window.scrollY;
            updatePlayerValue({ video: "trailer_weabove.mp4", title: "Trailer We Above", y: y });
        }}
    >


        <VideoItem
            withMask={true}
            srcMobile="trailer_weabove3_mob.mp4"
            srcTablet="trailer_weabove3.mp4"
            srcDesktop="trailer_weabove3.mp4"
            cls="230"
            withLoader={true}
        />

        <div class="buttons">
            <button
                on:click={() => {
                    let y = window.scrollY;
                    updatePlayerValue({ video: "trailer_weabove.mp4", title: "Trailer We Above", y: y });
                }}
                aria-label="Fullscreen"
            />
        </div>
    </div>


</div>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";

    .video2 {
        // width: 100%;
        // height: 250px;
        // background-color: orange;
        position: relative;
        margin-bottom: 50px;
        display: inline-flex;
        @include mode("desktop") {
            margin-right: 30px;
        }

        .buttons {
            width: 100%;
            position: absolute;
            bottom: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            // background-color: $color-grey;
            z-index: 1;
            @include mode("desktop") {
                height: 100%;
                justify-content: center;
            }

            button {
                border: none;
                background-color: $color-grey;
                background-color: $color-red;
                width: 50px;
                height: 50px;
                margin-bottom: 0;
                padding-bottom: 0;
                // border-radius: 50%;
                transform: translate(50%, 30%);
                background: url("./button_fullscreen_white_mob.webp") no-repeat;
                background-size: contain;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                transform: translate(56%, -21%);
                transform: translateY(calc(100% - 30px));
                transition: all 0.2s ease 0s;
                @include mode('tablet'){
                    background: url("./button_fullscreen_white.webp") no-repeat;
                    background-size: contain;
                }
                @include mode("desktop") {

                    transform: scale(1.3);
                    opacity: 0;
                    width: 65px;
                    height: 65px;
                    // transform: translate(15px, calc(100% - 50px));
                }
            }

            @include mode("desktop") {
                cursor: pointer;
            }
            &:hover button {
                @include mode("desktop") {
                    transform: scale(1);
                    opacity: 1;
                    cursor: pointer;
                    & :global(svg path) {
                        fill: $color-red;
                        fill: white;
                    }
                }
            }
        }

        & :global(svg) {
            width: calc(100% + 80px);
            height: calc(100% + 80px);
            position: absolute;
            // left: -40px;
            // top: -40px;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
    .trailer {
        // min-height: 300px;
        width: 100%;
        // padding: 30px 0px 0px 30px;
        // background-color: orange;
        text-align: center;
        position: relative;

        // transform: translateY(-60px);
        opacity: 0;
        clip-path: polygon(100% 0px, 100% 0px, 100% 100%, 100% 100%);
        animation: trailerReveal 0.6s ease 0.2s forwards;
        animation-timing-function: steps(12);
        @keyframes trailerReveal {
            0% {
            }
            100% {
                opacity: 1;
                transform: translateX(0px);
                clip-path: polygon(0% 0px, 100% 0px, 100% 100%, 0% 100%);
            }
        }
        @include mode("desktop") {
            text-align: initial;
            padding: 0px 30px 30px 0px;
        }

        .videoWrap {
            width: 200px;
            width: 100%;
            min-height: 300px;
            background-color: $color-grey;
            color: white;
            font-family: "Anton";
            font-size: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            animation: video 2s linear 0s infinite;
            box-shadow: 0px 0px 0px 0px;
            position: relative;
            transition: all 0.3s ease 0s;
            span {
                display: inline-flex;
                width: 60px;
                height: 60px;
                justify-content: center;
                align-items: center;
                z-index: 1;
                // background-color: black;
            }
            .playRound {
                display: block;
                width: 150px;
                height: 150px;
                background-color: lighten(black, 3%);
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate3d(-50%, -50%, 0px);
                border-radius: 50%;
                transition: all 0.6s ease 0s;
                clip-path: circle(0%);
                z-index: 0;
            }
            &:hover {
                background-color: black;
                .playRound {
                    clip-path: circle(100%);
                }
            }

            &::before {
                content: "";
                width: 100%;
                height: 100%;
                top: 0px;
                left: 0px;
                position: absolute;
                animation: video 2s linear 1s infinite;
            }
            @keyframes video {
                0% {
                }
                50% {
                    box-shadow: -30px -30px 0px 0px rgba(0, 0, 0, 0.2);
                }
                100% {
                    box-shadow: -30px -30px 0px 0px rgba(0, 0, 0, 0);
                }
            }
        }

        & :global(.line) {
            left: 0px !important;
        }
    }
</style>
