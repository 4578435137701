<script>
    import MaskTitle from "../svg/mask_title.svg";
    import { onMount } from "svelte";
    export let text = "";
    export let white = false;
    export let mini = false;
    let startAnim = false;

    const typewriter = (node, { speed = 3 }) => {
        const valid = node.childNodes.length === 1 && node.childNodes[0].nodeType === Node.TEXT_NODE;

        if (!valid) {
            throw new Error(`This transition only works on elements with a single text node child`);
        }

        const text = node.textContent;
        const duration = text.length / (speed * 0.01);

        return {
            duration,
            tick: (t) => {
                const i = Math.trunc(text.length * t);
                node.textContent = text.slice(0, i);
            },
        };
    };
    let currentId = Math.floor(Math.random() * 100);
    onMount(() => {
        document.addEventListener(`aos:in:bigTitle${currentId}`, ({ detail }) => {
            if (detail.classList.contains("aos-animate")) {
                 startAnim = true;
                
            } else {
                startAnim = false;
            }
        });
    });
</script>

<div
    class="bigTitle"
    class:white
    class:mini
    data-aos
    data-aos-offset="100"
    data-aos-id={`bigTitle${currentId}`}
>
    <!-- {#if startAnim}
        <h1 transition:typewriter>{text}</h1>
    {:else}
        <h1 class="opacity">
            {text}
        </h1>
    {/if} -->
    <h1 class:startAnim>
        <span class="fake" style={`--delay:0.2s;`}>{text}</span>
        <span class="fake" style={`--delay:0s;`}>{text}</span>
        <span class="fake" style={`--delay:0s;`}>{text}</span>
        <span class="fake" style={`--delay:0.4s;`}>{text}</span>
        <span class="real"> {text} </span>
    </h1>
    <!-- <MaskTitle /> -->
</div>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";
    .bigTitle {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 20px;
        font-family: Oswald;
        margin-bottom: 45px;

        &.mini{
            margin-bottom: 15px;
            font-size: 30px;
            @include mode('desktop'){
                justify-content: flex-start;
                font-size: 38px;
            }
        }
        .real{
            opacity: 0;
            z-index: 1;
            position: relative;
        }
        .fake{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            opacity: 0;
            --time:0.2s;
            color:black;
            user-select: none;
            pointer-events: none;
            user-select: none;
            &:first-child{
                clip-path: polygon(0px 0px, 30% 0px, 30% 50%, 10% 50%, 10% 100%, 0px 100%);
                transform:translateX(-30px);
                transition:all var(--time) ease 0.2s;

            }
            &:nth-child(2){
                clip-path: polygon(30% 0px, 85% 0px, 85% 50%, 30% 50%, 30% 0px);
                transform:translateY(-30px);
                transition:all var(--time) ease 0s;


            }
            &:nth-child(3){
                clip-path: polygon(10% 50%, 65% 50%, 65% 100%, 10% 100%, 10% 50%);
                transform:translateY(30px);
                transition:all var(--time) ease 0s;

            }
            &:nth-child(4){
                clip-path: polygon(85% 0%, 100% 0%, 100% 100%, 65% 100%, 65% 50%, 85% 50%, 85% 0px);
                transform:translateX(30px);
                transition:all var(--time) ease 0.4s;
            }
        }
        h1 {
            margin-top: 0;
            margin: 0;
            position: relative;

            &.opacity{
                opacity: 0;
            }

            &.startAnim .fake{
                transform:translate(0px, 0px);
                opacity: 1;
                animation: fake 0.2s 2 linear var(--delay) forwards;
                
                @keyframes fake {
                    0%{color:black;}
                    100%{color:$color-red;}
                }
            }
            &.startAnim .real{
                transition:all 0s ease 0.6s;
                z-index: 1;
                opacity: 1;
            }
        }
        text-align: center;
        font-size: 26px;
        color: $color-red;
        z-index: 1;
        position: relative;
        padding-bottom: 9px;
        padding-top: 5px;
        font-size: 36px;
        font-weight: bold;

        & :global(svg) {
            height: 20px;
        }
        @include mode("desktop") {
            font-size: 30px;
            margin-bottom: 60px;

            font-size: 16px;
            font-size: 60px;

            & :global(svg) {
                height: 40px;
            }
        }
        &.white {
            background-color: #fff;
        }
    }
</style>
