<script>
    import { onMount } from "svelte";

    let loaded = false;
    let videos = {
        1: "landing_altari_hd",
        2: "landing_freo_hd",
        3: "landing_ordos_hd",
    };

    let currentVideo = videos[Math.floor(Math.random() * 3) + 1];

    const confirmAccess = () => {
        document.cookie = "access=true;";
        document.location = "/";
    };

    onMount(() => {
        let teaserVideo = document.getElementById("teaserVideo");

        teaserVideo.onended = () => {
            teaserVideo.play();
        };
        // if (teaserVideo.readyState === 4) {
        //     // it's loaded
        //     console.log("loaded");
        // }

        // teaserVideo.addEventListener("loadeddata", (e) => {
        //     //Video should now be loaded but we can add a second check

        //     if (teaserVideo.readyState >= 3) {
        //         //your code goes here
        //         // console.log('LOADED NOW');

        //         teaserVideo.play();
        //         setTimeout(() => {
        //             loaded = true;
        //         }, [1000]);
        //     }
        // });

        var b = setInterval(() => {
            // console.log(teaserVideo.readyState);

            if (teaserVideo.readyState >= 3) {
                //This block of code is triggered when the video is loaded

                //your code goes here
                // console.log('L');
                //stop checking every half second
                teaserVideo.play();
                setTimeout(() => {
                    loaded = true;
                }, [1000]);
                clearInterval(b);
            }
        }, 800);
        // teaserVideo.onloadeddata = () => {
        //     teaserVideo.play();
        //     setTimeout(() => {
        //         loaded = true;
        //     }, [1000]);
        // };

        teaserVideo.addEventListener("ended", () => {
            teaserVideo.play();
        });
    });
</script>

<div class="fakeBody">
    <div class="overlay" class:on={loaded} />
    <div class="videoWrap" class:on={loaded}>
        <video id="teaserVideo" muted playsinline autoplay>
            <source src={`/${currentVideo}.mp4`} type="video/mp4" />
            <!-- <source src={`./landing_altari_hd.mp4`} type="video/mp4" /> -->
        </video>
    </div>

    <div class="loading" class:hide={loaded}>
        <span class="loadingWord"> <span class="content">LOADING</span> <span class="dot" /></span>
    </div>

    <button class="access_website" class:on={loaded} on:click={confirmAccess}> ENTER </button>
</div>

<style type="text/scss">
    @import "../rules/mixins.scss";
    @import "../rules/colors.scss";
    .fakeBody {
        background-color: black;
        margin: 0;
        padding: 0;
    }

    .videoWrap {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        background-color: black;
        display: flex;
        justify-content: center;
        clip-path: circle(0px);
        -webkit-clip-path: circle(0px);
        transition: all 1s ease 0s;
        -webkit-transition: all 1s ease 0s;
        &.on {
            clip-path: circle(100%);
            -webkit-clip-path: circle(100%);
        }
    }

    .videoWrap video {
        width: 100%;
        max-width: 100%;
        max-height: 100vh;
    }

    img {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
    }

    .access_website {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        background-color: transparent;
        bottom: 60px;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        border: solid 1px rgba(255, 255, 255, 0.3);
        max-width: 200px;
        min-height: 60px;
        font-family: "Poppins";
        box-sizing: border-box;
        color: white;
        // color:$color-red;
        font-size: 24px;
        z-index: 3;
        opacity: 0;
        transition: all 0.3s ease 0.3s;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        transform: translateY(20px);
        -webkit-transform: translateY(20px);
        -moz-transform: translateY(20px);
    }

    .access_website.on {
        opacity: 1;
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        &:hover {
            background-color: #fff;
            color: $color-red;
        }
    }

    .comingSoon {
        color: rgba(255, 255, 255, 0.2);
        display: flex;
        justify-content: center;
        font-size: 16px;
        position: fixed;
        bottom: 0px;
        left: 0;
        width: 100%;
        font-family: "Poppins";
        box-sizing: border-box;
        text-align: center;
        padding: 30px;
        z-index: 2;
        font-weight: normal;
        opacity: 0;
        transition: all 0.3s ease 0.3s;
        -webkit-transition: all 0.3s ease 0s;
        -moz-transition: all 0.3s ease 0s;
        transform: translateY(20px);
        -webkit-transform: translateY(20px);
        -moz-transform: translateY(20px);
    }

    .comingSoon.on {
        opacity: 1;
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
    }

    .loading {
        width: 100%;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-family: "Didact Gothic";
        font-weight: bold;
        transition: opacity 0.2s ease 0s;
        -webkit-transition: opacity 0.2s ease 0s;
        -moz-transition: opacity 0.2s ease 0s;
        pointer-events: none;
        font-size: 20px;
    }

    .loading.hide {
        opacity: 0;
    }

    .dot {
        width: 100%;
        height: 100vh;
        display: inline-flex;
        background-color: #fff;
        width: 10px;
        height: 10px;
        animation: loadingDot 0.1s linear 0.4s infinite;
        -webkit-animation: loadingDot 0.1s linear 0.4s infinite;
        -moz-animation: loadingDot 0.1s linear 0.4s infinite;
        background-color: transparent;
    }

    .content {
        animation: loadingWord 0.4s ease 0s forwards;
        -webkit-animation: loadingWord 0.4s ease 0s forwards;
        -moz-animation: loadingWord 0.4s ease 0s forwards;
    }

    @keyframes loadingWord {
        0% {
            clip-path: polygon(0px 0px, 0px 0px, 0px 100%, 0px 100%);
        }
        100% {
            clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%);
        }
    }

    @keyframes loadingDot {
        0% {
            background-color: transparent;
        }
        50% {
            background-color: white;
        }
        100% {
            background-color: transparent;
        }
    }

    @media screen and (min-width: 1048px) {
        .comingSoon {
            font-size: 22px;
        }

        .loading {
            font-size: 45px;
        }
    }
</style>
