<script>
    export let text = "";
    export let fullname = "";
    let ended = false;

    text = `“ ${text} ”`;

    /*------------------------------*"
    - FUNCTIONNAL
    \*------------------------------*/
</script>

<div class="developed" data-aos data-aos-offset="100">
    <div class="desc">Web Developer | Designer | Visual Effects</div>
    <div class="author" class:on={ended}>
        {fullname}
    </div>
</div>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";

    .developed {
        text-align: center;

        .desc {
            display: inline-flex;
            justify-content: center;
            clip-path: polygon(50% 0px, 50% 0px, 50% 100%, 50% 100% );
        }

        .author {
            font-weight: bold;
            transform:translateY(20px);
            opacity: 0;
            transition: all 0.2s ease 0s;
            font-family: 'Didact Gothic';
        }
        :global(&.aos-animate .author){
            opacity: 1 !important;
            transform:translateY(0px) !important;
            transition: all 0.3s ease 0.2s !important;

        }
        :global(&.aos-animate .desc){

            transition: all 0.3s ease 0.1s !important;
            -webkit-clip-path: polygon(0% 0px, 100% 0px, 100% 100%, 0% 100% ) !important;
            clip-path: polygon(0% 0px, 100% 0px, 100% 100%, 0% 100% ) !important;

        }
    }
</style>
