<script>
    import { onMount } from "svelte";
    import { onPlayer } from "../stores/stores";
    import gsap from "gsap";
    import { Elastic } from "gsap";
    import { tick, afterUpdate, beforeUpdate } from "svelte";
    let isPlaying = false;
    let triggerAnim = false;
    let isReady = false;
    let onPlayerValue = null;
    let currentDuration;
    var duration;
    let durationWidth;
    let isMoving = false;
    let isMovingTrigger = null;
    let mute = false;
    let videoPlayer;
    let fullscreen = false;
    let videoBuff;
    let y = 0;

    onPlayer.subscribe((val) => {
        onPlayerValue = val;
    });

    const updatePlayerValue = () => {
        onPlayer.update((n) => false);
    };

    $: {
        if (onPlayerValue) {
            videoBuff = onPlayerValue;
            viewPanel();
            setTimeout(toggleFullscreen, 1000);
            y = +onPlayerValue?.y;
            load();
        }
    }

    const startPlay = () => {};

    beforeUpdate(() => {
        let video = document.querySelector("#video");
        video;
    });

    const toggleFullscreen = (value = null) => {
        if (value != null) {

        } else {
            if (!fullscreen) {
                if (videoPlayer.webkitRequestFullscreen) {
                    videoPlayer.webkitRequestFullscreen();
                    fullscreen = true;
                } else {
                    videoPlayer.requestFullscreen();
                    fullscreen = true;
                }
            } else {

                if (document.exitFullscreen) {
                    fullscreen = false;
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    /* Safari */
                    fullscreen = false;
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    /* IE11 */
                    fullscreen = false;
                    document.msExitFullscreen();
                }
            }
        }
    };

    const viewPanel = () => {
        isMoving = true;

        clearTimeout(isMovingTrigger);
        isMovingTrigger = setTimeout(() => {
            isMoving = false;
        }, 3000);
    };

    const load = async () => {
        await tick();
        let video = document.querySelector("#video");

        video.load();

        video.onloadedmetadata = () => {
            isReady = true;
            duration = parseInt(video.duration);
        };

        video.onloadeddata = () => {
            setTimeout(play, 1000);
        };

        video.onplaying = () => {
            if (!isPlaying) {
                isPlaying = true;
            }
        };
        video.ontimeupdate = () => {
            currentDuration = video.currentTime;
            durationWidth = (currentDuration * 100) / duration;
        };

        video.onended = () => {
            close();
        };

        video.onpause = () => {
            isPlaying = false;
        };
    };

    onMount(() => {

        if (document.addEventListener) {
            document.addEventListener("fullscreenchange", exitHandler, false);
            document.addEventListener("mozfullscreenchange", exitHandler, false);
            document.addEventListener("MSFullscreenChange", exitHandler, false);
            document.addEventListener("webkitfullscreenchange", exitHandler, false);
        }
    });

    const play = () => {
        let video = document.querySelector("#video");


        if (onPlayerValue) {
            if (!isPlaying) {
                document.querySelector("#video").play();
            } else {
                document.querySelector("#video").pause();
            }
        }

    };

    const stop = () => {
        let video = document.querySelector("#video");
        video.pause();
        video.currentTime = 0;
    };

    const start = () => {
        let video = document.querySelector("#video");
        video.pause();
        video.currentTime = 0;
        video.play();
    };

    const exitHandler = () => {
        if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
            // Run code on exit
            window.scrollTo({ top: y, behavior: "auto" });
        }
    };

    const close = (e) => {
        isMoving = false;
        isPlaying = false;
        launchAnim();
        stop();
        updatePlayerValue();
        // console.log("we there man", y);
        // toggleFullscreen(false);
        if (document.exitFullscreen) {
            fullscreen = false;
            document.exitFullscreen();
            // console.log("normal");
        } else if (document.webkitExitFullscreen) {
            /* Safari */
            fullscreen = false;
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            /* IE11 */
            fullscreen = false;
            document.msExitFullscreen();
        }
    };

    const muteOrUnmute = () => {
        let video = document.querySelector("#video");
        video.muted = !video.muted;
        mute = video.muted;
    };

    $: getMaskTransition = () => {
        launchAnim();
        // return isMoving ? `M-10,0 L360,0 L370,100 L0,100 Z` : `M-10,0 L360,0 L370,100 L0,100 Z`;
        return isMoving ? `M-10,0 L360,0 L370,100 L0,100 Z` : `M-10,0 L-10,0 L0,100 L0,100 Z`;
    };

    const launchAnim = () => {
        if (isMoving) {
            clearTimeout(triggerAnim);
            triggerAnim = setTimeout(() => {
                gsap.to(".playerBtn.common", {
                    y: 0,
                    opacity: 1,
                    duration: 1.6,
                    ease: Elastic.easeOut,
                    delay: 0,
                    stagger: { each: 0.1 },
                });
            }, 300);
        } else {
            if (onPlayerValue) {
                clearTimeout(triggerAnim);
                triggerAnim = setTimeout(() => {
                    gsap.to(".playerBtn.common", {
                        y: 30,
                        opacity: 0,
                        duration: 0.3,
                        overwrite: true,
                    });
                }, 0);
            }
        }
    };

    const lines = {
        line1: { start: "M332,0 L332,0 L332,100 L332,100", end: "M332,0 L-50,0 L0,100 L332,100" },
        line2: { start: "M0,0 L0,0 L0,100 L0,100", end: "M0,0 L332,0 L332,120 L0,100" },
        line3: { start: "M332,0 L332,0 L332,100 L332,100", end: "M332,0 L0,0 L0,100 L332,100" },
    };

    $: getLine = (line = 1) => {
        // console.log('getline');
        let from = !onPlayerValue ? "start" : "end";
        // console.log(lines?.[`line${line}`]?.[from]);
        return lines?.[`line${line}`]?.[from];
    };
</script>

<svg class="maskGlobalPlayer" viewBox="0 0 100 100" preserveAspectRatio="none" class:on={onPlayerValue}>
    <clipPath id="myClipPlayer" clipPathUnits="objectBoundingBox" transform="scale(0.003033 0.0116279)">
        <path class="line1" d={getLine()} style:--delay="0s" />
    </clipPath>
    <clipPath id="myClipPlayer2" clipPathUnits="objectBoundingBox" transform="scale(0.003033 0.0116279)">
        <path class="line2" d={getLine(2)} style:--time="0.25s" style:--delay="0.3s" />
    </clipPath>
    <clipPath id="myClipPlayer3" clipPathUnits="objectBoundingBox" transform="scale(0.003033 0.0116279)">
        <path class="line2" d={getLine(3)} style:--time="0.2s" style:--delay="0.55s" />
    </clipPath>
</svg>
<!-- on:mouseenter={() => {startAnim = true; console.log('anim')}} -->

<div
    class="player"
    bind:this={videoPlayer}
    class:on={onPlayerValue}
    class:moving={isMoving}
    on:click={(e) => {
        // if (e.target == document.querySelector(".player")) {
        //     close();
        // }
    }}
    on:mousemove={viewPanel}
>
    <div class="layer1" />
    <div class="layer2" />
    <div class="layer3" />
    <div id="video_wrap">
        {#if videoBuff}
            <video id="video" playsinline key={videoBuff?.video}>
                <source src={videoBuff?.video} />
            </video>
        {/if}
        <button class="playerBtn closeSvg" aria-label="Close fullscreen video" on:click={close} />

        <div class="panel">
            <div class="panelBackground" />
            <div class="wrap">
                <div class="buttons">
                    <button class="playerBtn common prevSvg" aria-label="Previous" on:click={start} />
                    <button class="playerBtn common play" aria-label="Play" class:isPlaying on:click={play} />
                    <button
                        class="playerBtn common muteSvg"
                        class:muted={mute}
                        aria-label="Mute"
                        on:click={muteOrUnmute}
                    />
                </div>
                <div class="durationLine">
                    <div class="durationProgress" style={`--width:${durationWidth}%`}>
                        <div class="durationRange" />
                    </div>
                </div>
                <!-- <div class="currentTitle">{videoBuff?.title || ""}</div> -->
            </div>
        </div>

        <svg class="maskPlayer" viewBox="0 0 100 100" preserveAspectRatio="none">
            <!-- <defs> -->
            <clipPath id="clipPlayer" clipPathUnits="objectBoundingBox" transform="scale(0.003033 0.0116279)">
                <path class="slin" d={getMaskTransition()} />
            </clipPath>
        </svg>
    </div>
</div>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";

    .layer1,
    .layer2,
    .layer3 {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        // background-color: rgba(0, 0, 0, 0.6);
        background-color: rgba(0, 0, 0, 0.99);
        background-color: rgba(0, 0, 0, 1);
        pointer-events: none;
    }

    .layer1 {
        // background-color: grey;
        mask-image: url("brush_spray_01_mob.webp") no-repeat cover;
        -webkit-mask-image: url("brush_spray_01_mob.webp");
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-size: cover;
        -webkit-mask-size: cover;
        clip-path: url(#myClipPlayer);

        @include mode("tablet") {
            mask-image: url(brush_spray_01.png) no-repeat cover;
            -webkit-mask-image: url(brush_spray_01.png);
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: cover;
        }
        // background:url
        // brush_spray_03
    }

    .layer2 {
        // background-color: lightblue;
        mask-image: url("brush_spray_02_mob.webp") no-repeat cover;
        -webkit-mask-image: url("brush_spray_02_mob.webp");
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-size: cover;
        -webkit-mask-size: cover;
        clip-path: url(#myClipPlayer2);
        @include mode("tablet") {
            mask-image: url(brush_spray_02.png) no-repeat cover;
            -webkit-mask-image: url(brush_spray_02.png);
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: cover;
        }
        // display: none;
    }

    .layer3 {
        // background-color: black;
        // display: none;
        mask-image: url("brush_spray_03_mob.webp") no-repeat cover;
        -webkit-mask-image: url("brush_spray_03_mob.webp");
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-size: cover;
        -webkit-mask-size: cover;
        clip-path: url(#myClipPlayer3);
        @include mode("tablet") {
            mask-image: url(brush_spray_03.png) no-repeat cover;
            -webkit-mask-image: url(brush_spray_03.png);
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: cover;
        }
        // display: none;
    }

    .maskGlobalPlayer {
        position: fixed;
        left: -999%;
        // path {
        //     transition: all 0.15s ease var(--delay);
        // }
        .line1,
        .line2,
        .line3 {
            transition: all var(--time, 0.3s) linear var(--delay);
            -webkit-transition: all var(--time, 0.3s) linear var(--delay);
            transition-timing-function: steps(4, end);
            -webkit-transition-timing-function: steps(4, end);
        }
    }

    .player {
        // clip-path: url(#myClipPlayer);
    }

    .playerBtn {
        transition: opacity 0s ease 0s, transform 0s ease 0.3s;
        transform: translateY(30px);
        @include mode("desktop") {
            &:hover {
                transform: scale(1.2) !important;
            }
        }
    }
    .slider {
        -webkit-appearance: slider-vertical;
        height: 40px;
        background: #d3d3d3;
        outline: none;
        opacity: 0.7;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;
        display: none;
    }

    .slin {
        transition: d 0s ease 0.4s;
        opacity: 0;
    }

    .maskPlayer {
        position: fixed;
        left: -999%;
    }

    .prevSvg {
        background: url("button_rewind_02.webp") no-repeat center;
        background-size: contain;
    }

    .play {
        background: url("button_play_02.webp") no-repeat center;
        background-size: contain;
        background-position: center center;
        &.isPlaying {
            background: url("button_hold_02.webp") no-repeat center;
            background-size: contain;
            background-position: center center;
        }
    }

    .muteSvg {
        background: url("button_unmute.webp") no-repeat center;
        background-size: contain;

        &.muted {
            background: url("button_mute_02.webp") no-repeat center;
            background-size: contain;
        }
    }

    .closeSvg {
        background: url("button_cross_02.webp") no-repeat center;
        background-size: contain;
        position: fixed;
        top: 30px;
        right: 30px;
        transform: translateY(-10px);
        opacity: 0;
        transition: all 0.2s ease 0s;
    }

    :global(.closeSvg svg) {
        width: 16px;
    }
    :global(.closeSvg path) {
        fill: white;
    }
    :global(.prevSvg svg) {
        width: 16px;
        transform: rotate(180deg);
    }
    :global(.prevSvg path) {
        fill: white;
    }

    :global(.play path) {
        fill: white;
    }

    .slider:hover {
        opacity: 1;
    }

    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        background: #04aa6d;
        cursor: pointer;
    }

    .slider::-moz-range-thumb {
        width: 25px;
        height: 25px;
        background: #04aa6d;
        cursor: pointer;
    }
    .signature {
        width: 150px;
        text-align: center;
        position: absolute;
        font-family: Wendy One;
        left: 50%;
        top: 0;
        display: block;
        transform: translate(-50%, calc(-100% - 10px));
        color: white;
        position: relative;
        &::before,
        &::after {
            content: "";
            position: absolute;
            width: 50px;
            height: 3px;
            background-color: white;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            transform-origin: bottom;
            transform: rotate(45deg);
        }
    }

    .wrap {
        position: relative;
        max-width: 1248px;
        margin: auto;
        width: 100%;
        height: 100%;
        // border: solid 1px black;
        // display: flex;
        // justify-content: center;
        display: grid;
        align-items: center;
        column-gap: 30px;
        z-index: 1;
        padding: 0px 30px 30px;

        @include mode("tablet") {
            padding: 0px 30px;

            column-gap: 60px;
            grid-template-columns: min-content 1fr auto;
        }
        .durationLine {
            height: 10px;
            // width: 60%;
            background-color: #fff;
            position: relative;
            // width: 320px;
            border-radius: 40px;
            overflow: hidden;
            background-color: #2a2b3f;
            background-color: #151624;
            background-color: #232331;
            box-shadow: 0px 0px 4px 1px darken($color-grey, 6%);
            opacity: 0;
            transition: all 0.4s ease 0s;

            @include mode("desktop") {
                height: 15px;
                transform: translateY(2px);
            }
            .durationProgress {
                height: 100%;
                background-color: blue;
                position: absolute;
                top: 0;
                left: 0;
                width: var(--width, 0%);
                background-color: #5d557d;
                background-color: #28486f;
            }
            .durationRange {
                width: 40px;
                height: 40px;
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                cursor: pointer;
            }
        }
    }

    .buttons {
        // position: absolute;
        // left: 0;
        // top: 0;
        // right: 0;
        // margin: auto;
        display: flex;
        justify-content: center;
        transform: translateY(-10px);
        transform: translateY(-15px);
        column-gap: 15px;
    }
    .player {
        margin: auto;
        position: relative;
        position: fixed;
        width: 100%;
        left: 0;
        top: 0;
        // height: 100%;
        height: 100vh;
        // background-color: rgba(0, 0, 0, 0.99);
        // opacity: 0;
        pointer-events: none;
        z-index: 3;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 1s ease 0s;
        box-sizing: border-box;
        @media (orientation: landscape) {
            padding: 0;
        }
        @include mode("desktop") {
            // padding: 60px;
        }
        &.on {
            opacity: 1;
            pointer-events: visible;
        }

        &::before {
            content: "";
            position: absolute;
            width: calc(100% - 120px);
            height: 100%;
        }
    }
    video {
        width: 100%;
        opacity: 0;
        transform: translateY(10px);

        @include mode("desktop") {
            max-height: 100vh;
        }
    }

    #video_wrap {
        // width:70vw;
        // width:100vw;
        // height: 70vh;
        opacity: 0;
        display: inline-flex;

        position: relative;
        @include mode("desktop") {
            // border-radius: 10px;
            // border: solid 16px #080909;
            // border-bottom: solid 15px #080909;
            // box-shadow: 0px 0px 6px 1px #292f2f;
            width: 100%;
        }

        .panel {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            // display: flex;
            // justify-content: center;
            bottom: 0px;
            position: fixed;
            // background-color: orange;
            // background-color: black;

            // display: flex;
            // opacity: 0;

            @include mode("tablet") {
                height: 70px;
            }
            .panelBackground {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: url("bande_grise_video_mob4.webp") no-repeat;
                background-size: calc(100% + 120px) calc(100% + 30px);
                background-position: -60px top;
                background-position: -60px -4px;
                z-index: 1;
                clip-path: url(#clipPlayer);
                opacity: 0;
                transition: opacity 0.4s ease 0s;

                @include mode('tablet'){
                    background-image: url("bande_grise_video.webp");
                    
                }

                @include mode("tablet") {
                    background-size: calc(100% + 60px) calc(100% + 30px);
                }
            }

            .currentTitle {
                color: white;
                text-align: center;
                font-family: Oswald;
                font-weight: bold;
                text-transform: uppercase;
                font-size: 20px;
                padding: 10px 0px 15px;
                transition: all 0.3s ease 0s;
                opacity: 0;
                transform: translateX(10px);
                @include mode("tablet") {
                    padding: 0;
                }
            }
        }
    }

    .player.on {
        video {
            opacity: 1;
            transform: translateY(0);
        }
        #video_wrap {
            transition: all 0.3s ease 0.75s;
            opacity: 1;

            // animation: open 1s ease 0s forwards;
            // @keyframes open {
            //     0% {
            //         clip-path: polygon(50% 0%, 50% 0%, 50% 100%, 50% 100%);
            //     }
            //     100% {
            //         clip-path: polygon(-5% -5%, 105% -5%, 105% 105%, -5% 105%);
            //     }
            // }
        }
    }

    button {
        width: 50px;
        height: 50px;
        border: none;
        // background-color: rgba(0, 0, 0, 0.9);

        color: white;
        font-weight: bold;
        cursor: pointer;
        position: relative;
        z-index: 1;
        box-shadow: 0px 0px 0px 0px grey;

        &::before {
            z-index: -1;
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: calc(100% + 4px);
            height: 100%;
            border-radius: 50%;
            // background-color: orange;
            clip-path: polygon(50% 0px, 100% 0px, 100% 50%, 50% 50%);
        }
        // transition: all 0.2s ease 0s;
        @include mode("tablet") {
            width: 60px;
            height: 60px;
            &:hover {
                // box-shadow: 0px 0px 4px 2px rgba(255, 255, 255, 0.2) inset;
                // box-shadow: 0px 0px 2px 2px grey ;
                // box-shadow: 0px 0px 3px 12px $color-grey;
                // background-color: rgba(0, 0, 0, 1);
            }
        }
    }

    .player {
        .panel {
            transition: all 0.4s ease 0s;
            transform: translateY(50%);
            transform: translate(0px, 0px);
        }
        .buttons {
            transition: all 0.4s ease 0s;
            // transform: translateY(calc(-100% - 40px));
        }
        &.moving {
            .panelBackground {
                transition: opacity 0s linear 0s !important;
                opacity: 1 !important;
            }
            .closeSvg {
                transition: all 0.2s ease 0s !important;
                opacity: 1;
                transform: translateY(0px);
            }

            .playerBtn {
                transition: initial;
            }

            .slin {
                transition: d 0.6s ease-out 0s, opacity 0s ease 0s;
            }
            .panel {
                transform: translate(0px, 0px);
            }
            .buttons {
                // transform: translateY(0px);
                // transform: translateY(calc(-100% - 15px));
            }
            .currentTitle {
                transition: all 0.6s ease 0.9s !important;
                // opacity: 1 !important;
                transform: translateX(0px) !important;
            }

            .durationLine {
                transition: all 0.3s ease 0.4s;
                opacity: 1;
            }
        }
    }
</style>
