<script>
    import RealnftItem2 from "./RealnftItem2.svelte";
    // import RealnftItem2 from "./RealnftItem2_w.svelte";
    import Title from "../common/Title.svelte";
    import BigTitle from "../common/BigTitle.svelte";
    import AOS from "aos";
    import { onNft, responsive, fullsizeStore } from "../stores/stores.js";
    import Lone from "../svg/Lone.svelte";
    import { tick } from "svelte";
    import VideoItem from "../common/VideoItem.svelte";

    import { onMount } from "svelte";
import Service from "../service/Service.svelte";
    let realnftNode;
    let size;
    let autoWidth = true;
    let currentFaction = "ordos";

    const selectFaction = (faction = "ordos") => {
        currentFaction = faction;
    };

    const selectNft = (nftValue) => {
        onNft.update((n) => nftValue);
    };

    let responsiveValue;
    responsive.subscribe((val) => {
        responsiveValue = val;
    });

    let nfts = {
        altari: [
            {
                order: 1,
                video: `altari/ALTARI_SITE_1`,
            },

            {
                order: 2,
                video: `altari/ALTARI_SITE_2`,
            },
            {
                order: 3,
                video: `altari/ALTARI_SITE_3`,
            },
            {
                order: 4,
                video: `altari/ALTARI_SITE_4`,
            },

            {
                order: 5,
                video: `altari/ALTARI_SITE_5`,
            },
            {
                order: 6,
                video: `altari/ALTARI_SITE_6`,
            },
            {
                order: 7,
                video: `altari/ALTARI_SITE_7`,
            },

            {
                order: 8,
                video: `altari/ALTARI_SITE_8`,
            },
            {
                order: 9,
                video: `altari/ALTARI_SITE_9`,
            },

        ],
        freo: [
            {
                order: 1,
                video: `freo/FREO_SITE_1`,
            },

            {
                order: 2,
                video: `freo/FREO_SITE_2`,
            },
            {
                order: 3,
                video: `freo/FREO_SITE_3`,
            },
            {
                order: 4,
                video: `freo/FREO_SITE_4`,
            },

            {
                order: 5,
                video: `freo/FREO_SITE_5`,
            },
            {
                order: 6,
                video: `freo/FREO_SITE_6`,
            },
            {
                order: 7,
                video: `freo/FREO_SITE_7`,
            },

            {
                order: 8,
                video: `freo/FREO_SITE_8`,
            },
            {
                order: 9,
                video: `freo/FREO_SITE_9`,
            },

        ],
        ordos: [
            {
                order: 1,
                video: `ordos/ORDOS_SITE_1`,
            },

            {
                order: 2,
                video: `ordos/ORDOS_SITE_2`,
            },
            {
                order: 3,
                video: `ordos/ORDOS_SITE_3`,
            },
            {
                order: 4,
                video: `ordos/ORDOS_SITE_4`,
            },

            {
                order: 5,
                video: `ordos/ORDOS_SITE_5`,
            },
            {
                order: 6,
                video: `ordos/ORDOS_SITE_6`,
            },
            {
                order: 7,
                video: `ordos/ORDOS_SITE_7`,
            },

            {
                order: 8,
                video: `ordos/ORDOS_SITE_8`,
            },
            {
                order: 9,
                video: `ordos/ORDOS_SITE_9`,
            },

        ],
    };

    $: {
        if (currentFaction) {
            refresh();
        }
    }

    const refresh = async () => {
        await tick();
        AOS.refreshHard();
    };

    const updateFullsizeStoreValue = (currentValue = null) => {
        fullsizeStore.update((val) => currentValue);
    };

    let currentNft;

    let types = {
        1: "type1",
        2: "type2",
        3: "type3",
    };

    const renderType = () => {
        return types[Math.floor(Math.random() * 3) + 1];
    };

    const getBackground = (currentKey) => {
        let lastDigit = parseInt(String(currentKey).slice(-1));
        let currentBackground =  lastDigit == 9 ? 1 : lastDigit + 1;
        // console.log({currentBackground});
        return currentBackground;
    }
</script>

<div class="realnft" data-aos>
    <BigTitle text="NFT" />
    <div class="line" />
    <div class="buttons">
        <div class="outfit" />
        <div>
            <button
                class="select_faction ordos"
                class:selected={currentFaction == "ordos"}
                on:click={() => selectFaction("ordos")}
            >
                ORDOS
            </button>
            <button
                class="select_faction freo"
                class:selected={currentFaction == "freo"}
                on:click={() => selectFaction("freo")}
            >
                FREO
            </button>
            <button
                class="select_faction altari"
                class:selected={currentFaction == "altari"}
                on:click={() => selectFaction("altari")}
            >
                ALTARI
            </button>
        </div>
        <div class="outfit" />
    </div>
    <div class="c_realnft">
        <!-- {#if currentFaction == "altari"} -->
            {#each nfts?.[currentFaction] as nft, key (nft.video)}
                <div
                    class={`nft-item ${renderType()}`}
                    style:background-image={`url(fond_NFT_0${getBackground(key)}.webp)`}
                    style:--delay={`0.0${key}s`}
                    data-aos
                    data-aos-offset="100"
                >
                    <VideoItem
                        trigger={() => {
                            updateFullsizeStoreValue({
                                withMask: true,
                                srcMobile: `${nft.video}_MD.mp4`,
                                srcTablet: `${nft.video}_HD.mp4`,
                                srcDesktop: `${nft.video}.mp4`,
                                cls: "300",
                            });
                        }}
                        hasFullscreen={true}
                        withMask={true}
                        srcMobile={`${nft.video}_SD.mp4`}
                        srcTablet={`${nft.video}_MD.mp4`}
                        srcDesktop={`${nft.video}_HD.mp4`}
                        cls="100"
                        withLoader={true}
                    />
                </div>
            {/each}

    </div>
</div>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";

    .nft-item {
        // background: url();
        background-size: 100% 100%;
        padding: 5px 10px;
        transition: all 0.6s ease var(--delay, 0s);
        opacity: 0;
        @include mode("tablet") {
            padding: 5px 15px;
        }
        @include mode("desktop") {
            padding: 5px 25px;
        }
        // transition-timing-function: steps(30);
        &.type1 {
            clip-path: polygon(0px 0px, 0px 0px, 0px 0px);
        }
        &.type2 {
            clip-path: polygon(0px 0px, 0px 0px, 0px 100%, 0px 100%);
        }
        &.type3 {
            clip-path: polygon(100% 0px, 100% 0px, 100% 100%, 100% 100%);
        }
        :global(&.aos-animate) {
            opacity: 1 !important;
        }
        :global(&.aos-animate.type1) {
            clip-path: polygon(0px 0px, 200% 0px, 0px 200%) !important;
        }
        :global(&.aos-animate.type2) {
            clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%) !important;
        }
        :global(&.aos-animate.type3) {
            clip-path: polygon(0% 0px, 100% 0px, 100% 100%, 0% 100%) !important;
        }
    }

    .buttons {
        display: grid;
        column-gap: 5px;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        grid-template-columns: minmax(0px, 250px) max-content minmax(0px, 250px);
        margin: auto;
        // margin-bottom: 15px;
        margin-bottom: 30px;

        @include mode("desktop") {
            column-gap: 15px;
            font-size: 1.6rem;
            max-width: 80%;
            margin-bottom: 40px;
        }
        .outfit {
            height: 29px;
            background: url("./zebra_f1_mob.webp");
            background-repeat: repeat-x;
            background-size: 250px 29px;
            @include mode("tablet") {
                background: url("./zebra_f.webp");
                background-repeat: repeat-x;
                background-size: 250px 29px;
            }
        }
    }

    button.select_faction {
        display: inline-flex;
        position: relative;
        transition: all 0.2s ease 0s;
        outline: none;
        border: none;
        color: $color-grey;
        background-color: transparent;
        font-family: "Didact Gothic";
        font-family: "OSWALD";
        font-weight: bold;
        z-index: 1;
        padding: 5px 20px;
        color: rgb(116, 116, 116);
        cursor: pointer;
        font-size: 20px;
        @include mode("tablet") {
            font-size: 30px;
        }
        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            z-index: -1;
            transition: all 0.2s ease 0s;
            transition-timing-function: steps(10);
            clip-path: polygon(0px 0px, 0% 0px, 0% 100%, 0px 100%);
        }

        &.freo {
            &::before {
                background: url("./brush_selection_freo_mob.webp") no-repeat;
                background-size: 100% 100%;
                @include mode("tablet") {
                    background: url("./brush_selection_freo.png") no-repeat;
                    background-size: 100% 100%;
                }
            }
        }

        &.altari {
            &::before {
                background: url("./brush_selection_altari_mob.webp") no-repeat;
                background-size: 100% 100%;
                @include mode("tablet") {
                    background: url("./brush_selection_altari.png") no-repeat;
                    background-size: 100% 100%;
                }
            }
        }

        &.ordos {
            &::before {
                background: url("./brush_selection_ordos_mob.webp") no-repeat;
                background-size: 100% 100%;
                @include mode("tablet") {
                    background: url("./brush_selection_ordos.png") no-repeat;
                    background-size: 100% 100%;
                }
            }
        }

        &.selected {
            color: rgb(46, 46, 46);
            &::before {
                clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%);
            }
        }
    }

    .s_wrap {
        text-align: center;
        z-index: 1;
        position: relative;
        img {
            width: 100%;
        }
    }
    .realnft {
        // min-height: 100vh;
        // background-color: $color-grey;
        // background-color: lightcoral;
        width: 100%;
        padding: 0px 15px 30px;
        padding-bottom: 0px;
        position: relative;
        z-index: 1;
        opacity: 0;
        opacity: 1;
        transition: all 0.4s ease 0.9s;
        overflow-x: hidden;

        @include mode("desktop") {
            padding: 0px 30px 30px;
            margin-top: 60px;
        }

        .point {
            width: 50px;
            display: none;
            @include mode("desktop") {
                display: inline-grid;
            }
        }
        // transform:translateY(60px);
        // -webkit-mask-repeat: no-repeat;
        // -webkit-mask-size: cover;

        .topRealNft {
            position: absolute;
            height: 200px;
            width: calc(100% + 150px);
            left: -30px;
            top: -100px;
            display: none;
        }

        .c_realnft {
            max-width: 1248px;
            // border: solid 1px #84859f;
            // padding: 30px;
            display: grid;
            column-gap: 30px;
            position: relative;
            padding-bottom: 50px;
            justify-content: center;
            align-items: center;
            margin: auto;
            border: none;
            row-gap: 15px;
            row-gap: 10px;
            grid-template-columns: 1fr 1fr 1fr;
            column-gap: 15px;

            @include mode("desktop") {
                column-gap: 30px;
                padding-bottom: 80px;

                grid-template-columns: 1fr 1fr 1fr;
            }
            @include mode("big") {
                column-gap: 60px;
                row-gap: 10px;
            }
        }

        .x {
            font-size: 40px;
            color: white;
        }

        .crystals {
            width: 200px;
            height: 200px;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.3);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            transform: translateY(50%);
            background-color: rgb(255, 255, 255);
            box-shadow: 0px 0px 0px 40px $color-grey;
            img {
                width: 50px;
            }
        }

        .line {
            position: absolute;
            height: 100%;
            width: 1px;
            left: 50%;
            margin-left: -1px;
            background-color: #fff;
            top: 0;
            background-color: #637a98;
            box-shadow: 0px 4px 4px #6ecae7;
            display: none;
        }
    }

    :global(.aos-animate.realnft) {
        // border: solid 1px #84859f;
        // transform:translateY(0px) !important;

        // opacity: 1 !important;
    }

    :global(.realnft .bigTitle) {
        background-color: transparent !important;
    }
</style>
