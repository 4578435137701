<script>
    import { onMenu, onGoTo, skipIntro, onLevel } from "./../stores/stores";
    import NavItem from "./NavItem.svelte";
    import FooterLines from "../footer/FooterLines.svelte";
    import InstagramLogo2 from "../svg/instagramLogo2.svg";
    import FacebookLogo2 from "../svg/facebookLogo2.svg";
    import TwitterLogo2 from "../svg/twitterLogo2.svg";
    import DiscordLogo2 from "../svg/discord2.svg";
    import Logo from "../svg/Logo.svelte";
    import Lone from "../svg/Lone.svelte";

    let onMenuValue;
    let skipIntroValue;
    let onLevelValue;

    onLevel.subscribe((val) => {
        onLevelValue = val;
    });

    onMenu.subscribe((val) => {
        onMenuValue = val;
    });
    skipIntro.subscribe((val) => {
        skipIntroValue = val;
    });

    const toggleMenuState = () => {
        onMenu.update((n) => !n);
    };

    const setGoToState = (to = "") => {
        onGoTo.update((n) => to);
    };

    const closeMenuState = () => {
        onMenu.update((n) => false);
    };

    $: {
        if (onMenuValue) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "auto";
        }
    }

    // let firstLineStart = "M30,2 L0,2 L0,2";
    // let secondLineStart = "M30,15 L0,15 L0,15";
    // let thirdLineStart = "M0,28 L30,28 L30,28";
    // let firstLineEnd = "M0,2 L0,2 L28,28";

    let lines = {
        first: {
            start: "M30,4 L0,4 L0,4",
            end: "M0,2 L0,2 L30,28",
        },
        second: {
            start: "M30,15 L0,15 L0,15",
            end: "M15,15 L15,15 L15,15",
        },
        third: {
            start: "M30,26 L0,26 L0,26",
            end: "M0,28 L0,28 L30,2",
        },
    };

    $: renderBurger = (currentLine = "first") => {
        if (onMenuValue) {
            return lines?.[currentLine]?.end;
        } else {
            return lines?.[currentLine]?.start;
        }
    };
</script>

<div class="menu" class:on={onMenuValue} class:skip={skipIntroValue}>
    <div class="outfit">
        <img class="logo4" src={`logo3_mobile.webp`} alt="Logo fond sombre" on:click={() => {
            setGoToState("HOME");
            closeMenuState();
        }}/>

        <div class="burger" on:click={toggleMenuState}>
            <svg viewBox="0 0 30 30" class="menuSvg">
                <path class="firstLine" d={renderBurger("first")} />
                <path class="secondLine" d={renderBurger("second")} />
                <path class="thirdLine" d={renderBurger("third")} />
            </svg>
        </div>
    </div>
    <div class="fakeOutfit" />

    <div class="icon" on:click={() => setGoToState("HOME")}>
        <picture>
            <source media="(max-width: 1024px)" srcset={`header_mob.gif`} />
            <source media="(min-width: 1024px)" srcset={`header_des.gif`} />
            <img src="header_mob.gif" alt="logo" loading="lazy"/>
        </picture>
    </div>
    <nav class="nav">
        <FooterLines height={2} />
        <FooterLines mirrored={true} height={2} />
        <div class="top_outfit" style:--delay={`0s`} />
        <div class="left_outfit" style:--delay={`0.2s`} />
        <div class="left_outfit_inner" style:--delay={`0.2s`} />
        <div class="bottom_outfit" style:--delay={`0.4s`} />
        <div class="bottom_outfit_inner" style:--delay={`0.4s`} />
        <img class="group_outfit" src="./group_outfit.svg" alt="outfit" />
        <ul class="wrapMenu">
            <li class="logoWebsite">
                <!-- <img class="logo3" src={`logo_fond_sombre.png`} alt="" /> -->
                <!-- <img class="logo3" src={`logo2desktop.png`} alt="logo Desktop" /> -->
                <picture>
                    <source media="(max-width: 500px)" srcset={`logo_goto.webp`} />
                    <source media="(max-width: 1024px)" srcset={`logo2desktop.png`} />
                    <img class="logo3" src="logo2desktop.png" alt="WeAbove Logo" on:click={() => setGoToState("HOME")}/>
                </picture>
            </li>
            <li class="mainMenu">
                <ul class="">
                    <NavItem text="LORE" index={1} />
                    <NavItem text="NFT" index={2} />
                    <!-- <NavItem text="ROADMAP" index={3} comingSoon={true} goTo={false}/> -->
                    <NavItem text="ROADMAP" index={3}/>
                </ul>
                <ul>
                    <NavItem text="CREW" index={4} />
                    <NavItem text="FACTIONS" index={5} />
                    <NavItem text="FAQ" index={6} />
                </ul>
            </li>
            <li>
                <ul class="socials">
                    <li>
                        <span class="socialSentence">Socials <span></span> </span>
                        <a
                            href="https://twitter.com/weaboveofficial"
                            target="_blank"
                            aria-label="We Above Twitter"
                            rel="noopener noreferrer"
                        >
                            <img class="twitter" src="logo_twitter.webp" alt="" />
                        </a>
                    </li>

                    <li>
                        <a
                            class="discordLogo"
                            href="https://discord.gg/weabove"
                            aria-label="We Above Discord"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src="logo_discord.webp" class="discord" alt="" />
                        </a>
                    </li>
                    <li>

                        <a
                            class="openseaLogo"
                            href="https://opensea.io/fr/collection/weabove-prime"
                            aria-label="We Above Opensea"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src="logo_opensea.svg" class="opensea" alt="" />
                        </a>
                    </li>
                </ul>
            </li>
        </ul>
        <div class="crystalContainer">
            <!-- <MainCrystal /> -->
        </div>
    </nav>
</div>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";

    .left_outfit,
    .left_outfit_inner,
    .bottom_outfit,
    .bottom_outfit_inner,
    .top_outfit {
        position: absolute;
        left: 0;
        transition: all 0.2s ease var(--delay);
        transition-timing-function: steps(6);
        z-index: 3;
        @include mode("desktop") {
            display: none;
        }
    }

    .socials{
        transform:translateX(30px);
        opacity: 0;
        @include mode('desktop'){
            transform:translateX(0);
            opacity: 1;
        }
    }

    .group_outfit {
        position: absolute;
        bottom: -34px;
        width: 100%;
        right: -10px;
        max-width: 400px;

        transform: translateY(20px);
        opacity: 0;
        z-index: 4;
        @include mode("desktop") {
            display: none;
        }
    }

    .top_outfit {
        width: 100%;
        height: 30px;
        top: -20px;
        right: -5px;
        background: url("./top_outfit.webp") no-repeat;
        background-size: 100% calc(100%);
        transform: scaleX(0);
        transform-origin: right;
        transition: all 0.2s ease 0.4s;
        // transition-timing-function: steps(6);
        // -webkit-transition-timing-function: steps(6);
        z-index: 10;
        // z-index: 1;
    }

    .left_outfit {
        width: 20px;
        width: 40px;
        height: calc(100% + 60px);
        height: calc(100% + 60px - 17px);

        left: -5px;
        left: -21px;
        // top: 10px;
        background: url("./left_outfith.webp") no-repeat;

        // background: url("./left_outfit2.png") no-repeat;
        // background: url("./left_outfit32.webp") no-repeat;
        background-size: 100% calc(100%);
        background-position: 0px -20px;
        background-position: 0px -3px;
        z-index: 10;
        clip-path: polygon(0px 0px, 100% 0px, 100% 0px, 0px 0px);

        // transform: scaleY(0);
        transform-origin: top;
        transition: all 0.2s ease 0.2s;
        transition-timing-function: steps(6);

    }
    .left_outfit_inner {
        width: 50px;
        height: calc(100% + 60px);
        left: 0px;
        background: url("./left_outfit_inner2.webp") no-repeat;
        // background: url("./left_outfit_inner2.png") no-repeat;
        background-size: 100% calc(100%);
        background-position: 0px -20px;
        // transform: scaleY(0);
        transform-origin: top;
        transition: all 0.2s ease 0.2s;
        transition-timing-function: steps(6);
        z-index: 9;
        clip-path: polygon(0px 0px, 100% 0px, 100% 0px, 0px 0px);

    }

    .bottom_outfit {
        z-index: 1;
        width: 100%;
        height: 38px;
        bottom: -45px;
        background: url("./bottom_outfit.webp") no-repeat;
        background-size: 100% calc(100%);
        transform: scaleX(0);
        transform-origin: left;
        transition: all 0.2s ease 0s;
        transition-timing-function: steps(6);

    }

    .bottom_outfit_inner {
        width: 100%;
        height: 40px;
        bottom: -30px;
        background: url("./bottom_outfit_inner.webp") no-repeat;
        background-size: 100% calc(100%);
        transform: scaleX(0);
        transform-origin: left;
        transition: all 0.2s ease 0s;
        transition-timing-function: steps(6);

    }

    .socialSentence {
        display: inline-flex;
        width: 130px;
        padding: 10px;
        // background: url("./socials.png");
        background-size: 100% 100%;
        padding-left: 30px;
        margin-right: 10px;
        font-size: 18px;
        font-family: "Heebo";
        font-weight: bold;
        position: relative;
        &::before, &::after, span{
            display: block;
            content:'';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            opacity: 0;
        }
        &::before{
            background: url("./socials_top.png");
            background-size: 100% 100%;
            transform-origin: right;
            transform:scaleX(0);
        }
        &::after{
            background: url("./socials_left.png");
            background-size: 100% 100%;
            transform-origin: top;
            transform:scaleY(0);
        }
        span{
            background: url("./socials_bottom.png");
            background-size: 100% 100%;
            transform-origin: left;
            transform:scaleX(0);

        }
        @include mode("desktop") {
            display: none;
        }
    }


    .menu.on {
        .left_outfit,
        .left_outfit_inner,
        .bottom_outfit,
        .bottom_outfit_inner,
        .top_outfit {
            transform: scale(1);
            transition: all 0.2s ease var(--delay);
            transition-timing-function: steps(6);
        }
        .left_outfit{
            clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%);
        }
        .left_outfit_inner{
            clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%);
        }
        .group_outfit{
            transition: all 0.2s ease 0.6s;
            transition-timing-function: steps(6);
            transform:translate(0px, 0px);
            opacity: 1;
        }
        .nav{
            pointer-events: visible;
            background-color: #fff;
            // background:url('./bg.webp') no-repeat;
            &::before{
                content:'';
                background:url('./bg.webp') no-repeat;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                opacity: 0.26;
                opacity: 0.35;
                z-index: 1;
            }
            @include mode('desktop'){
                background: initial;
                background-color: transparent;
            }
        }

        .socials{
            transform: translateX(0);
            opacity: 1;
            transition: all 0.4s ease 0.6s;
            .socialSentence{
                &::before{
                    transition: all 0.2s ease 0.7s;
                    opacity: 1;
                    transform:scaleX(1);
                }
                &::after{
                    transition: all 0.2s ease 0.9s;
                    opacity: 1;
                    transform:scaleY(1);
                }
                span{
                    transition: all 0.2s ease-in-out 1.1s;
                    opacity: 1;
                    transform:scaleX(1);
                }
            }
        }
    }

    .icon video,
    .icon img {
        width: 100%;
        clip-path: polygon(50% 0px, 100% 50%, 50% 100%, 0% 50%);
        @include mode("desktop") {
            margin-top: -1px;
        }
    }


    #video2 {
        pointer-events: none;
    }

    .menuLeft,
    .menuRight,
    .menuTopRight,
    .menuTopLeft {
        pointer-events: none;
        @include mode("desktop") {
            display: none;
        }
    }
    .menuLeft {
        position: absolute;
        left: 50px;
        top: 0;
        width: 50px;
        height: 100%;
        path {
            fill: transparent;
            stroke: $color-red;
            -webkit-filter: drop-shadow(0px 4px 4px #fa4e4e);
            filter: drop-shadow(0px 4px 4px #fa4e4e);
            stroke-dasharray: 412;
            stroke-dashoffset: 1236;
        }
    }

    .menuRight {
        position: absolute;
        right: 50px;
        top: 0;
        width: 50px;
        height: 100%;
        path {
            fill: transparent;
            stroke: $color-red;
            -webkit-filter: drop-shadow(0px 4px 4px #fa4e4e);
            filter: drop-shadow(0px 4px 4px #fa4e4e);
            stroke-dasharray: 100;
            stroke-dasharray: 412;
            stroke-dashoffset: 1236;
            // stroke-dashoffset: 100;
        }
    }
    .menuTopRight {
        position: absolute;
        right: 0;
        top: 0;
        width: 51px;
        path {
            fill: transparent;
            stroke: $color-red;
            stroke-dasharray: 70;
            stroke-dashoffset: 212;
        }
    }
    .menuTopLeft {
        position: absolute;
        left: 0;
        top: 0;
        width: 51px;
        path {
            fill: transparent;
            stroke: $color-red;
            stroke-dasharray: 70;
            stroke-dashoffset: 212;
            // stroke-dashoffset: 159;
        }
    }

    svg {
        width: 100%;
        height: 100%;
        position: absolute;
        path {
            fill: white;
            stroke: white;
            stroke-width: 2px;
            transition: all 1s ease 0s;
            transition: all 0.4s ease 0s;
        }
    }

    .burger {
        position: absolute;
        width: 40px;
        height: 30px;
        // background-color: rgba(0, 0, 0, 0.6);
        border-radius: 2px;
        top: 5px;
        top: 10px;
        left: 5px;
        transition: all 0.2s ease 0s;
        @include mode("desktop") {
            display: none;
        }
    }

    .logo4 {
        position: absolute;
        right: 5px;
        top: 5px;
        right: 7px;
        height: 40px;
        // transform:rotateY(180deg);
        @include mode("desktop") {
            display: none;
        }
    }

    .fond {
        position: absolute;
        left: 0;
        top: 0px;
        width: 100%;
        width: calc(100% + 30px);
        height: calc(100% + 15px);
        left: -17px;
        top: -7px;
        top: -10px;
    }

    .discordLogo :global(path) {
        fill: white !important;
    }
    .discordLogo :global(rect) {
        fill: $color-grey;
        fill: #8f9ba2;
    }

    .logo3 {
        height: 55px;
        height: 45px;
        height: 55px;
        z-index: 1;
        display: none;
        @include mode("desktop") {
            cursor: pointer;
            display: inline-flex;
        }
    }
    .menu {
        height: 50px;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3;
        transition: 0.4s ease 0s;
        font-family: Heebo;
        font-weight: bold;

        @include mode("desktop") {
            height: 60px;
        }

        .outfit {
            position: relative;
            height: 100%;
            background-color: $color-grey;
            z-index: 2;

            .secondOutfit {
                @include mode("desktop") {
                    width: 100%;
                    height: 100%;
                    left: 0;
                    top: 0;
                    background-color: darken($color-grey, 10%);
                    opacity: 0;
                    transition: all 0.2s ease 0s;
                }
            }
            &.on {
                .secondOutfit {
                    opacity: 1;
                }
            }

            @include mode("desktop") {
                z-index: 1;
                // background: url("bg.svg");
                // background-size: cover;
                // position: absolute;
                // width: 100%;
                // height: 100%;
                // background-attachment: fixed;
                // left: 0;
                // top: 0;
                clip-path: polygon(0px 0px, 100% 0px, 100% 60px, 0px 60px);

                height: calc(100vh + var(--road, 400px));
                transform: translateY(0px);
                background-color: orange;
                position: fixed;
                background: url("../bg.svg") no-repeat;
                background-size: cover;
                background-attachment: fixed;
                width: 100%;
                overflow-x: hidden;
                background-position: 0px var(--bgy, var(--road, 400px));
            }
        }

        .icon {
            z-index: 3;
            width: 64px;
            height: 80px;
            height: 64px;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 50%);
            bottom: 0px;
            clip-path: polygon(0px 0%, 100% 0%, 100% 100%, 0px 100%);
            transition: clip-path 0.6s ease 0.6s;
            cursor: pointer;

            @include mode("desktop") {
                background-color: transparent;
                clip-path: initial;
                width: 80px;
                height: 80px;
            }
        }

        .nav {
            position: absolute;
            bottom: 0px;
            width: 100%;
            height: auto;
            // background-color: #272838;
            // background-color: #3d3e50;
            // background-color: white;
            z-index: 1;
            transition: all 0.6s cubic-bezier(0, 1.09, 1, 1) 0s;
            box-shadow: 0px 0px 0px 0px transparent;
            // padding: 0px 30px 0px 60px;
            padding: 0px 0px 20px 40px;
            transform: translateY(100%);
            pointer-events: none;
            @include mode("desktop") {
                transform: none;
                box-shadow: none;
                pointer-events: visible;
            }
            .crystalContainer {
                display: none;
                position: absolute;
                bottom: 0;
                left: 50%;
                height: 64px;
                width: 64px;
                transform: translate3d(-50%, 50%, 0px);
                clip-path: polygon(0px 50%, 100% 50%, 100% 100%, 0px 100%);
                @include mode("desktop") {
                    display: none;
                }
            }

            ul {
                list-style-type: none;
                padding: 0;
                margin: 0;
                &.wrapMenu {
                    margin: auto;
                    // width: calc(100% - 100px);
                    width: 100%;
                    // max-width: 230px;
                    // padding: 30px 0px 30px 15px;
                    padding: 10px 0px 0px 0px;
                    position: relative;
                    z-index: 2;
                    @media screen and (orientation: landscape) {
                        // width: initial;
                        // max-width: 100%;

                        max-height: calc(100vh - 100px);
                        overflow-y: scroll;
                    }

                    @include mode("desktop") {
                        width: initial;
                        max-width: 100%;
                        padding: 0;

                        overflow-y: hidden;
                        & :global(.squareRound) {
                            display: none !important;
                        }

                        margin: initial;
                        height: 60px;
                        display: grid;
                        justify-content: center;
                        column-gap: 60px;
                        grid-template-columns: 150px 1fr 150px;
                    }
                }
                &.socials {
                    display: flex;
                    column-gap: 15px;
                    align-items: center;
                    justify-content: center;
                    min-height: 60px;
                    margin-top: 15px;
                    @include mode("desktop") {
                        margin-top: 0;
                        justify-content: flex-end;
                    }
                    li {
                        display: flex;
                        align-items: center;
                        width: auto;
                    }
                    & :global(svg) {
                        height: 30px;
                        cursor: pointer;
                        transition: all 0.2s ease 0s;
                    }
                    & :global(svg path) {
                        transition: all 0.2s ease 0s;
                    }
                    & :global(svg:hover path) {
                        fill: white;
                    }
                    & :global(path) {
                        fill: #777aa2;
                        fill: #8f9ba2;
                    }
                }
            }

            li {
                width: 100%;
                @include mode("desktop") {
                    height: 60px;
                }
                &.logoWebsite {
                    display: flex;
                    align-items: center;
                    position: relative;
                }
                &.mainMenu {
                    @include mode("desktop") {
                        display: grid;
                        grid-template-columns: calc(50% - 60px) calc(50% - 60px);
                        column-gap: 120px;
                    }
                    @include mode('big'){
                        grid-template-columns: calc(50% - 70px) calc(50% - 70px);
                        column-gap: 140px;
                        
                    }

                    ul {
                        @include mode("desktop") {
                            display: flex;
                            justify-content: center;
                            column-gap: 30px;

                            &:first-child {
                                justify-content: flex-end;
                            }
                            &:last-child {
                                justify-content: flex-start;
                            }
                        }

                        @include mode("big") {
                            column-gap: 60px;
                        }
                    }
                }
            }
            @include mode("desktop") {
                background-color: transparent;
                z-index: 2;

                margin: auto;
                left: 0;
                right: 0;

                padding: 0px 0px;
                padding: 0px 15px;
                position: relative;

                & :global(svg) {
                    bottom: -1px;
                    top: initial;
                }
            }
        }

        &.on {
            .burger {
                transform: scale(0.6);
            }

            .icon {
                transition: clip-path 0s ease 0s;
                clip-path: polygon(0px 0%, 100% 0%, 100% 0%, 0px 0%);
                @include mode("desktop") {
                    clip-path: initial;
                }
            }
        }
    }

    .twitter,
    .discord, .opensea {
        width: 40px;
    }
    .discord {
        border-radius: 5px;
    }

</style>
