<script>
    import Copyright from "./Copyright.svelte";
    import Socials from "./Socials.svelte";
</script>

<footer class="footer">
    <Socials />
    <div class="cgv">
        <a href="/cgv.pdf" download>Terms & Conditions</a>
    </div>
    <Copyright />
</footer>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";
    .footer {
        position: relative;
        width: 100%;
        padding: 0px 15px 0px;
        margin-top: 50px;
        background-color: $color-grey;

        @include mode("desktop") {
            border-top: none;
            padding: 15px 30px 0px;
            background-color: #ffffffbd;
            mask-image: url("pied_de_page.webp");
            -webkit-mask-image: url("pied_de_page.webp");
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-size: 100% 100%;
            mask-size: 100% 100%;
        }

        .cgv {
            text-align: center;
            padding-bottom: 10px;
            padding-top: 10px;
            @include mode("desktop") {
                padding-top: 0px;
                padding-bottom: 15px;
            }

            a {
                // font-weight: bold;
                font-size: 14px;
                color: #a5b4bd;
                text-decoration: none;
                cursor: pointer;
                transition:all 0.2s ease 0s;
                @include mode("desktop") {
                    &:hover {
                        color: #798f9d;
                    }
                }
            }
        }
    }
</style>
