<script>
    import Title from "../common/Title.svelte";
    import BigTitle from "../common/BigTitle.svelte";
    import Highlighting from "../common/Highlighting.svelte";
    import Citation from "../common/Citation.svelte";
    import Paragraphe from "../common/Paragraphe.svelte";
    import MainP from "../common/MainP.svelte";
    import Jewel from "./Jewel.svelte";
    import Fullscreen from "../svg/fullscreen.svg";
    import VideoItem from "../common/VideoItem.svelte";
    import AOS from "aos";

    import { onPlayer } from "../stores/stores";

    import { tick } from "svelte";

    const updatePlayerValue = (value = "") => {
        onPlayer.update((n) => value);
    };

    let currentFaction = "ordos";

    const selectFaction = (faction = "ordos") => {
        currentFaction = faction;
    };

    $: {
        // console.log({ currentFaction });
        if (currentFaction) {
            refresh();
        }
    }

    const refresh = async () => {
        await tick();
        AOS.refreshHard();
    };
</script>

<div class="exploitation" data-aos data-aos-offset="100">
    <div class="topBg" />
    <div class="bottomBg" />

    <div class="line">
        <img src="line.svg" alt="line" />
    </div>
    <div class="buttons faction">
        <div class="outfit" />
        <div>
            <button
                class="select_faction ordos"
                class:selected={currentFaction == "ordos"}
                on:click={() => selectFaction("ordos")}
            >
                ORDOS
            </button>

            <button
                class="select_faction freo"
                class:selected={currentFaction == "freo"}
                on:click={() => selectFaction("freo")}
            >
                FREO
            </button>

            <button
                class="select_faction altari"
                class:selected={currentFaction == "altari"}
                on:click={() => selectFaction("altari")}
            >
                ALTARI
            </button>
        </div>
        <div class="outfit" />
    </div>

    <div class="explanation" id="factions">
        <div class="left">
            <!-- <div class="buttons_wrap">
                <button
                    class="select_faction"
                    class:selected={currentFaction == "ordos"}
                    on:click={() => selectFaction("ordos")}
                >
                    ORDOS
                </button>
                <button
                    class="select_faction"
                    class:selected={currentFaction == "altari"}
                    on:click={() => selectFaction("altari")}
                >
                    ALTARI
                </button>
                <button
                    class="select_faction"
                    class:selected={currentFaction == "freo"}
                    on:click={() => selectFaction("freo")}
                >
                    FREO
                </button>
            </div> -->

            <div class="introduction">
                {#if currentFaction == "ordos"}
                    <BigTitle text="THE ORDOS" mini={true} />
                {:else if currentFaction == "altari"}
                    <BigTitle text="ALTARI" mini={true} />
                {:else if currentFaction == "freo"}
                    <BigTitle text="FREO" mini={true} />
                {/if}

                {#if currentFaction == "ordos"}
                    <MainP>
                        The Ordos use refined gravite to develop advanced technologies, seeking to use their
                        superiority to impose their version of order on what is left of humanity.
                    </MainP>
                    <Citation
                        text="From a broken Earth, we shall construct the perfect society where logic, knowledge,
                    and order prevails."
                        author="Speaker Chigudu, Head of Applied Governmental Sciences"
                    />
                {:else if currentFaction == "altari"}
                    <MainP>
                        The Altari believe that raw gravite is a gift from the divine, and that it is to be
                        the fuel that transforms themselves and the world.
                    </MainP>
                    <Citation
                        text="Despite this corrupt world, the gravite offers us the true metamorphosis, from which
                    enlightenment blooms."
                        author="Gray Popov, Exarch for the Upper Reach"
                    />
                {:else if currentFaction == "freo"}
                    <MainP>
                        The Freo clans are for anyone and everyone who doesn’t want to be controlled by some
                        grand purpose or higher power, and are willing to fight to their last breath to
                        protect their freedom.
                    </MainP>
                    <Citation
                        text="Even on this craptastic planet, I get plenty of joy in the freedom of living my own
                    life on my own terms."
                        author="Chantel Byers, Sectoid Farmer"
                    />
                {/if}
            </div>
        </div>
        <div class="right">
            <div class="videoWrapper">
                <span class="sentence">
                    {#if currentFaction == "ordos"}
                        <Highlighting>Ordo ab chao</Highlighting>
                    {:else if currentFaction == "altari"}
                        <Highlighting>Mutatis mutandis mutandus</Highlighting>
                    {:else if currentFaction == "freo"}
                        <Highlighting>Live free or die trying</Highlighting>
                    {/if}
                </span>
                {#if currentFaction == "ordos"}
                    <VideoItem
                        trigger={() => {
                            // let y = +info?.getAttribute("data-y");
                            let y = window.scrollY;
                            updatePlayerValue({
                                video: "presentation_ordos.mp4",
                                title: "ORDOS Introduction",
                                y: y,
                            });
                        }}
                        withMask={true}
                        withLoader={true}
                        srcMobile="presentation_ordos_mob.mp4"
                        srcTablet="presentation_ordos.mp4"
                        srcDesktop="presentation_ordos.mp4"
                        cls="200"
                    />
                {:else if currentFaction == "altari"}
                    <VideoItem
                        trigger={() => {
                            let y = window.scrollY;
                            updatePlayerValue({
                                video: "presentation_altari.mp4",
                                title: "ORDOS Introduction",
                                y: y,
                            });
                        }}
                        withMask={true}
                        withLoader={true}
                        srcMobile="presentation_altari_mob.mp4"
                        srcTablet="presentation_altari.mp4"
                        srcDesktop="presentation_altari.mp4"
                        cls="200"
                    />
                {:else if currentFaction == "freo"}
                    <VideoItem
                        trigger={() => {
                            let y = window.scrollY;
                            updatePlayerValue({
                                video: "presentation_freo.mp4",
                                title: "ORDOS Introduction",
                                y: y,
                            });
                        }}
                        withMask={true}
                        withLoader={true}

                        srcMobile="presentation_freo_mob.mp4"
                        srcTablet="presentation_freo.mp4"
                        srcDesktop="presentation_freo.mp4"
                        cls="200"
                    />
                {/if}

                <div
                    class="buttons"
                    on:click={() => {
                        let y = window.scrollY;
                        updatePlayerValue({
                            video: `presentation_${currentFaction}.mp4`,
                            title: "ORDOS Introduction",
                            y: y,
                        });
                    }}
                >
                    <button
                        on:click={() => {
                            let y = window.scrollY;

                            updatePlayerValue({
                                video: `presentation_${currentFaction}.mp4`,
                                title: "ORDOS Introduction",
                                y: y,
                            });
                        }}
                        aria-label="Fullscreen"
                    />
                </div>
            </div>
        </div>
    </div>
</div>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";

    .buttons.faction {
        position: absolute;
        // left: 50%;
        top: -55px;
        top: -25px;
        width: 100%;
        display: flex;
        justify-content: center;
        column-gap: 10px;
        z-index: 1;
        left: 0;
        right: 0;
        margin: auto;

        margin-bottom: 15px;
        display: grid;
        column-gap: 5px;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        // transform: translateX(-100px);
        grid-template-columns: minmax(0px, 250px) max-content minmax(0px, 250px);
        grid-template-columns: minmax(0px, 450px) max-content minmax(0px, 450px);
        grid-template-columns: 1fr max-content 1fr;
        margin: auto;

        @include mode("tablet") {
            column-gap: 15px;
            // pointer-events: none;
        }
        @include mode("desktop") {
            width: 100%;
            right: initial;
            // left: 3%;
        }
        button.select_faction {
            display: inline-flex;
            position: relative;
            transition: all 0.2s ease 0s;
            outline: none;
            border: none;
            color: $color-grey;
            background-color: transparent;
            font-family: "Didact Gothic";
            font-family: "OSWALD";
            font-weight: bold;
            z-index: 1;
            padding: 5px 20px;
            color: rgb(116, 116, 116);
            cursor: pointer;
            font-size: 20px;
            @include mode("tablet") {
                font-size: 30px;
            }
            &::before {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                z-index: -1;
                transition: all 0.2s ease 0s;
                // transition-timing-function: steps(10);
                clip-path: polygon(0px 0px, 0% 0px, 0% 100%, 0px 100%);
            }

            &.freo {
                &::before {
                    background: url("./brush_selection_freo_mob.webp") no-repeat;
                    background-size: 100% 100%;

                    @include mode("tablet") {
                        background: url("./brush_selection_freo.png") no-repeat;
                        background-size: 100% 100%;
                    }
                }
            }

            &.altari {
                &::before {
                    background: url("./brush_selection_altari_mob.webp") no-repeat;
                    background-size: 100% 100%;

                    @include mode("tablet") {
                        background: url("./brush_selection_altari_mob.png") no-repeat;
                        background-size: 100% 100%;
                    }
                }
            }

            &.ordos {
                &::before {
                    background: url("./brush_selection_ordos_mob.webp") no-repeat;
                    background-size: 100% 100%;

                    @include mode("tablet") {
                        background: url("./brush_selection_ordos.png") no-repeat;
                        background-size: 100% 100%;
                    }
                }
            }

            &.selected {
                color: rgb(46, 46, 46);
                &::before {
                    clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%);
                }
            }
        }

        @include mode("desktop") {
            column-gap: 15px;
            font-size: 1.6rem;
            // max-width: 80%;
            margin-bottom: 40px;
        }
        .outfit {
            height: 29px;
            background: url("./zebra_f1_mob.webp");
            background-repeat: repeat-x;
            background-size: 250px 29px;
            @include mode("tablet") {
                background: url("./zebra_f1.png");
                background-repeat: repeat-x;
                background-size: 250px 29px;
            }
        }
    }

    .buttons_wrap {
        position: absolute;
        // left: 50%;
        left: 0;
        top: -55px;
        width: 100%;
        display: flex;
        justify-content: center;
        column-gap: 10px;
        // transform:translateX(calc(-100% - 100px));
        @include mode("desktop") {
            justify-content: flex-start;
        }
    }



    .fond {
        position: relative;
        img {
            width: 100%;
            max-width: 219px;
        }
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            width: 8px;
            height: 100%;
            display: none;
            @include mode("desktop") {
                display: none;
            }
        }
        &::before {
            left: 0;
        }
        &::after {
            right: 0;
        }
    }

    .videoWrapper {
        // display: flex;
        // justify-content: center;
        position: relative;
        max-width: 500px;
        margin:auto;
        @include mode('desktop'){
            max-width: initial;
            margin:initial;
        }
    }

    :global(.aos-animate.exploitation .highlighting_background) {
        clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%) !important;
        transition: all 0.4s ease 0.3s !important;
    }

    :global(.exploitation .highlighting .content) {
        padding: 5px 15px;
        font-size: 16px;
    }
    .exploitation {
        @include mode("desktop") {
            & :global(.highlighting .content) {
                font-size: 20px;
            }
        }
    }

    .exploitation {
        // overflow-x: hidden;
        padding: 30px 30px;
        position: relative;
        z-index: 1;
        margin-bottom: 30px;
        background-color: #fff;
        opacity: 0;
        clip-path: polygon(0px 0px, 0px 0px, -100px 100%, -100px 100%);
        transition: clip-path 0s ease 0.4s, opacity 0.4s ease 0s;
        padding-bottom: 60px;
        margin-top: 60px;

        .topBg,
        .bottomBg {
            background: url("trace_pink4_mob.webp") no-repeat;
            background-size: 100% 100%;
            height: 15px;
            width: 100%;
            position: absolute;
            left: 0;
            @include mode("tablet") {
                background: url("trace_pink4.webp") no-repeat;
                background-size: 100% 100%;
            }
        }
        .topBg {
            top: 0;
            display: none;
            @include mode("desktop") {
                // display: none;
            }
        }
        .bottomBg {
            bottom: 0;
        }

        @include mode("desktop") {
            background-size: calc(100% + 40px) 100%;
            // background-size: cover;
            background-position: -20px -10px;
            background-color: #fff;
        }

        .sentence {
            display: inline-flex;
            color: white;
            padding: 10px;
            left: 50%;
            top: 0;
            transform: translate(-50%, -50%);
            position: absolute;
            padding: 15px 10px 10px 10px;
            z-index: 1;
            font-family: Oswald;
            font-weight: bold;
            font-size: 22px;
        }

        .explanation {
            display: grid;
            max-width: 1248px;
            margin: auto;
            @include mode("desktop") {
                grid-template-columns: 1fr 1fr;
            }
        }

        .line {
            display: flex;
            align-items: center;
        }

        .left {
            display: grid;
            padding-top: 15px;
            @include mode("desktop") {
                grid-template-columns: auto 1fr;
                column-gap: 30px;
                padding: 50px 30px 50px 0px;
                padding: 30px 30px 30px 0px;
            }
        }

        .right {
            margin-top: 30px;
            @include mode("desktop") {
                margin-top: 0;
            }
            .buttons {
                width: 100%;
                position: absolute;
                bottom: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                // background-color: $color-grey;
                z-index: 1;
                @include mode("desktop") {
                    height: 100%;
                    justify-content: center;
                    // pointer-events: none;
                }

                button {
                    border: none;
                    background-color: $color-grey;
                    background-color: $color-red;
                    width: 50px;
                    height: 50px;
                    margin-bottom: 0;
                    padding-bottom: 0;
                    // border-radius: 50%;
                    transform: translate(50%, 30%);
                    background: url("./button_fullscreen_white_mob.webp") no-repeat;
                    background-size: contain;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    transform: translate(56%, -21%);
                    transform: translateY(calc(100% - 30px));
                    transition: all 0.2s ease 0s;
                    user-select: none;
                    @include mode("tablet") {
                        background: url("./button_fullscreen_white.webp") no-repeat;
                        background-size: contain;
                    }
                    @include mode("desktop") {
                        transform: scale(1.3);
                        opacity: 0;
                        width: 65px;
                        height: 65px;
                        // transform: translate(15px, calc(100% - 50px));
                    }
                }

                @include mode("desktop") {
                    cursor: pointer;
                }
                &:hover button {
                    @include mode("desktop") {
                        transform: scale(1);
                        opacity: 1;
                        cursor: pointer;
                        & :global(svg path) {
                            fill: $color-red;
                            fill: white;
                        }
                    }
                }
            }
            @include mode("desktop") {
                padding: 50px 0px 50px 50px;
                padding: 30px 0px 30px 50px;
            }
        }

        .crystalContainer {
            display: none;
            @include mode("desktop") {
                grid-column: 1;
                grid-row: 1;
                display: flex;
                align-items: center;
            }
        }

        .introduction {
            display: flex;
            flex-direction: column;
            align-items: center;
            @include mode("desktop") {
                // display: fle;
                // grid-column: 2;
                // grid-row: 1;
                align-items: center;
                align-items: flex-start;
                justify-content: center;
                :global(& .bigTitle) {
                    align-self: flex-start;
                }
            }
        }

        .fond {
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 40px;
            margin-bottom: 30px;
            @include mode("desktop") {
                margin-top: 0;
                margin-bottom: 0;
                display: block;
            }
        }

        .left,
        .right {
            position: relative;
            @include mode("desktop") {
            }
        }
        .line {
            position: absolute;
            height: 100%;
            width: 1px;
            left: 50%;
            top: 0;
            display: none;
            @include mode("desktop") {
                img {
                    height: 70%;
                }
                display: flex;
                align-items: center;
                transform: translateX(-4.5px);
            }
        }

        .circle {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            row-gap: 10px;
            transform: translateY(18px);
        }
        .line1,
        .line2,
        .line3 {
            display: flex;
            justify-content: center;
            column-gap: 0px;
            top: 0;
        }
        .line2,
        .line3 {
            transform: translateY(-20px);
        }
        .line3 {
            transform: translateY(-40px);
        }
    }

    :global(.aos-animate.exploitation) {
        opacity: 1 !important;
        transition: all 0.4s ease 0s !important;

        clip-path: polygon(0px 0px, calc(100% + 100px) 0px, 100% 100%, -100px 100%) !important;
        clip-path: polygon(0px -100px, calc(100% + 100px) -100px, 100% 100%, -100px 100%) !important;
    }
</style>
