<script>
    import Highlighting from "../common/Highlighting.svelte";
</script>
    <div class="socials gd-target">
        <div class="line"><img src="line.svg" alt="outfit" /></div>
        <div class="created">
            <img class="right" src="mask_title.svg" alt="outfit"/>
            <Highlighting>CREATED BY</Highlighting>
        </div>

        <div class="join">
            <Highlighting>JOIN US</Highlighting>
            <img class="right" src="mask_title.svg" alt="outfit"/>
        </div>
        <img class="createdImage" src="ming.svg"  alt="creator logo"/>
        <div class="socials_wrapper">
            <a href="https://twitter.com/weaboveofficial" target="_blank" rel="noopener noreferrer"><img src="logo_twitter.webp" alt="twitter" /></a>
            <a href="https://discord.gg/weabove" target="_blank" rel="noopener noreferrer"><img src="logo_discord.webp" alt="discord" /></a>
            <a href="https://raritysniper.com/nft-drops-calendar" target="_blank" rel="noopener noreferrer"><img src="raritysniper.png" alt="verify_raritysniper" /></a>
            <a href="https://opensea.io/fr/collection/weabove-prime" target="_blank" rel="noopener noreferrer"><img src="logo_opensea.svg" class="opensea" alt="opensea logo" /></a>
        </div>
    </div>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";

    .gd-target {
        display: grid;
        padding-top: 10px;
        grid-template-columns: 1fr 1fr;
        column-gap: 30px;
        max-width: 1248px;
        margin: auto;
        padding-bottom: 15px;
        justify-content: center;
        position: relative;
        row-gap: 10px;

        img.right {
            max-width: 200px;
            height: 15px;
        }

        @include mode("desktop") {
            display: grid;
            max-width: 826px;
            grid-template-columns: auto auto auto auto;
            column-gap: 15px;
        }

    }

    .line {
        position: absolute;
        left: 50%;
        height: calc(100% - 15px);
        top: 15px;
        img {
            position: absolute;
            height: 100%;
            top: 0;
        }
        @include mode("desktop") {
            display: none;
        }
    }

    .join,
    .created {
        display: grid;
        align-items: center;
        mask-image: url(fond_PP_05_mob.webp);
        -webkit-mask-image: url(fond_PP_05_mob.webp);
        -webkit-mask-repeat: no-repeat;
        mask-repeat: no-repeat;
        -webkit-mask-size: 100% 100%;
        mask-size: 100% 100%;
        font-family: "Oswald";
        column-gap: 10px;
        color: white;
        @include mode('desktop'){
            color: $color-grey;
        }
    }

    .created {
        grid-template-columns: auto 1fr;
        @include mode("desktop") {
            grid-template-columns: 1fr auto;
        }
    }
    .join {
        grid-template-columns: 1fr auto;
        justify-self: flex-end;

        @include mode("desktop") {
            grid-template-columns: auto 1fr;
            grid-column: 4;
            grid-row: 1;
        }
    }

    h1 {
        font-size: 30px;
        font-family: "WORK SANS";
        margin-bottom: 15px;
        margin-top: 30px;
        @include mode("desktop") {
            margin-bottom: 30px;
        }
    }

    .createdImage {
        height: 50px;
        height: 44px;
        justify-self: center;
        @include mode('desktop'){
            grid-column: 2;
            grid-row: 1;
        }
    }

    .socials_wrapper {
        column-gap: 15px;
        padding-left: 30px;
        display: inline-grid;
        grid-template-columns: min-content min-content;
        row-gap: 15px;
        img {
            width: 50px;
        }

        @include mode("desktop") {
            border-left: 1px solid $color-red;
            margin-left: 15px;
            grid-template-columns: min-content min-content min-content min-content;
        }
    }

</style>
