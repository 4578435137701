<script>
    export let question;
    export let fromLeft = true;
    export let index = 0;
    let genKey = Math.floor(Math.random() * (Math.floor(4) - Math.ceil(1) + 1)) + 1;
</script>

<!-- data-aos
data-aos-anchor-placement="bottom"
data-aos-offset="300" -->
<!-- style:--delay={`${index * 0.4 + 0.6}s`} -->
<!-- style:--delay={`${index * 0.2 + 0.6}s`} -->

<div
    class={`faqItem bg0${genKey}`}
    class:fromLeft
    class:fromRight={!fromLeft}
    style:--delay={`${index * 0.2 + 0.1}s`}
>
    <div class="question">{question}</div>
    <div class="answer"><slot /></div>
</div>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";
    .faqItem {
        background-color: #fff;

        padding: 5px 15px;
        padding: 30px 15px;
        font-weight: bold;
        font-family: Oswald;
        text-align: center;
        margin-bottom: 0px;
        mask-image: url("bande03_mob.webp") no-repeat cover;
        -webkit-mask-image: url("bande03_mob.webp");
        -webkit-mask-size: 100% 100%;
        @include mode("tablet") {
            mask-image: url("bande03_mob.png") no-repeat cover;
            -webkit-mask-image: url("bande03.png");
        }
        &.bg02 {
            mask-image: url("bande02_mob.webp") no-repeat cover;
            -webkit-mask-image: url("bande02_mob.webp");
            @include mode("tablet") {
                mask-image: url(bande02.png) no-repeat cover;
                -webkit-mask-image: url(bande02.png);
            }
        }
        &.bg03 {
            mask-image: url("bande03_mob.webp") no-repeat cover;
            -webkit-mask-image: url("bande03_mob.webp");
            @include mode("tablet") {
                mask-image: url(bande03.png) no-repeat cover;
                -webkit-mask-image: url(bande03.png);
            }
        }
        &.bg04 {
            mask-image: url("bande04_mob.webp") no-repeat cover;
            -webkit-mask-image: url("bande04_mob.webp");
            @include mode("tablet") {
                mask-image: url(bande04.png) no-repeat cover;
                -webkit-mask-image: url(bande04.png);
            }
        }
        // transition: all 0.4s ease 0s;

        &.fromLeft {
            // clip-path: polygon(100% 0px, 100% 0px, 100% 100%, 100% 100%);
            clip-path: polygon(0px 0px, 0% 0px, 0% 100%, 0px 100%);
            .question {
                transform: translateX(-30px);
            }
            .answer {
                transform: translateX(-30px);
            }
        }
        &.fromRight {
            clip-path: polygon(100% 0px, 100% 0px, 100% 100%, 100% 100%);
            .question {
                transform: translateX(30px);
            }
            .answer {
                transform: translateX(30px);
            }
        }

        // background: linear-gradient(90deg, transparent, white, transparent);
        @include mode("desktop") {
            padding: 30px;
        }

        .question {
            color: $color-red;
            transition: all 0.4s ease 0s;
            opacity: 0;
        }
        .answer {
            padding-left: 15px;
            font-size: 14px;
            max-width: 80ch;
            margin: auto;
            transition: all 0.4s ease 0s;
            opacity: 0;

            // display: none;
            & :global(li) {
                list-style-type: none;
                position: relative;
            }

        }
    }
    :global(.faq.aos-animate .faqItem) {
        transition: all 0.4s ease var(--delay);
        clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%) !important;
    }

    :global(.faq.aos-animate .faqItem .question) {
        transform: translateX(0px) !important;
        transition: all 0.4s ease var(--delay);

        opacity: 1 !important;
    }
    :global(.faq.aos-animate .faqItem .answer) {
        transform: translateX(0px) !important;
        transition: all 0.4s ease var(--delay);
        opacity: 1;

        // opacity: 1;
    }
</style>
