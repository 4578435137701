<script>
    import { onGoTo } from "./../stores/stores";
    import { onMount } from "svelte";

    import gsap from "gsap";
    const links = {
        HOME: "banner",
        NFT: "realnft",
        LORE: "story",
        ROADMAP: 'roadmap',
        FACTIONS: "exploitation",
        CREW: "team",
        FAQ: "faq",
    };

    export let nbX = 16;
    export let nbY = 9;

    const offset = (el) => {
        var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    };

    let length = nbX * nbY;
    let ratioX = length / nbX;
    let ratioY = length / nbY;

    let onGoToValue;
    onGoTo.subscribe((val) => {
        onGoToValue = val;
    });

    const setGoToState = (to = "") => {
        onGoTo.update((n) => to);
    };

    onMount(() => {
        let windowWidth = window.innerWidth;
        updateSquare(windowWidth);
        window.addEventListener("resize", (e) => {
            let width = e.target.innerWidth;
            updateSquare(width);
        });
        document.cookie = "access=; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    });

    const updateSquare = (windowWidth) => {
        if (windowWidth < 780) {
            // nbX = 4;
            nbX = 3;
            nbY = 7;
        } else if (windowWidth < 1000) {
            nbX = 5;
            nbY = 10;
        } else if (windowWidth < 1248) {
            nbX = 14;
            nbY = 6;
        } else if (windowWidth > 1248) {
            nbX = 16;
            nbY = 9;
        }
    };

    $: {
        if (onGoToValue != "") {
            let position = document.querySelector(`.${links?.[onGoToValue]}`);
            let { top } = offset(position);
            setTimeout(() => {
                window.scrollTo({ top: top - 100, behavior: "auto" });
                setGoToState("");
            }, [1000]);
        }
    }

    const lines = {
        line1: { start: "M332,0 L332,0 L332,100 L332,100", end: "M332,0 L-50,0 L0,100 L332,100" },
        line2: { start: "M0,0 L0,0 L0,100 L0,100", end: "M0,0 L332,0 L332,120 L0,100" },
        line3: { start: "M332,0 L332,0 L332,100 L332,100", end: "M332,0 L0,0 L0,100 L332,100" },
    };

    $: getLine = (line = 1) => {
        let from = !onGoToValue ? "start" : "end";
        return lines?.[`line${line}`]?.[from];
    };
</script>

<div class="goto" style={`--nbX:${nbX};--nbY:${nbY};--rowHeight:${Math.ceil(100 / nbY)}%`}>
    <div class="layer1" />
    <div class="layer2">
        <picture>
            <source media="(max-width: 500px)" srcset={`logo_goto.webp`} />
            <source media="(max-width: 1024px)" srcset={`logo2desktop.png`} />
            <source media="(min-width: 1024px)" srcset={`logo2desktop.png`} />
            <img src="logo_goto.webp" alt="WeAbove Logo" />
        </picture>
    </div>
    <div class="layer3" />

    <svg class="maskGlobalGoto" viewBox="0 0 100 100" preserveAspectRatio="none" class:on={onGoToValue}>
        <clipPath id="myClipGoto" clipPathUnits="objectBoundingBox" transform="scale(0.003033 0.0116279)">
            <path class="line1" d={getLine()} style:--delay="0s" />
        </clipPath>
        <clipPath id="myClipGoto2" clipPathUnits="objectBoundingBox" transform="scale(0.003033 0.0116279)">
            <path class="line2" d={getLine(2)} style:--time="0.25s" style:--delay="0.3s" />
        </clipPath>
        <clipPath id="myClipGoto3" clipPathUnits="objectBoundingBox" transform="scale(0.003033 0.0116279)">
            <path class="line3" d={getLine(3)} style:--time="0.2s" style:--delay="0.55s" />
        </clipPath>
    </svg>
</div>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";
    .maskGlobalGoto {
        position: fixed;
        left: -999%;
        // path {
        //     transition: all 0.15s ease var(--delay);
        // }
        .line1,
        .line2,
        .line3 {
            transition: all var(--time, 0.3s) linear var(--delay);
            -webkit-transition: all var(--time, 0.3s) linear var(--delay);
            transition-timing-function: steps(4, end);
            -webkit-transition-timing-function: steps(4, end);
        }
    }
    .layer1,
    .layer2,
    .layer3 {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        // background-color: rgba(0, 0, 0, 0.6);
        background-color: rgba(0, 0, 0, 0.99);
        background-color: rgba(0, 0, 0, 1);
        pointer-events: none;
    }

    .layer1 {
        background-color: $color-grey;
        background-color: #dfe8ed;
        mask-image: url("brush_spray_01_mob.webp") no-repeat cover;
        -webkit-mask-image: url("brush_spray_01_mob.webp");
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-size: cover;
        -webkit-mask-size: cover;
        clip-path: url(#myClipGoto);
        @include mode("tablet") {
            mask-image: url(brush_spray_01.png) no-repeat cover;
            -webkit-mask-image: url(brush_spray_01.png);
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: cover;
        }
        // background:url
        // brush_spray_03
    }

    .layer2 {
        background-color: lightblue;
        background-color: lighten(#dfe8ed, 5%);

        mask-image: url("brush_spray_02_mob.webp") no-repeat cover;
        -webkit-mask-image: url("brush_spray_02_mob.webp");
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-size: cover;
        -webkit-mask-size: cover;
        clip-path: url(#myClipGoto2);
        display: flex;
        align-items: center;
        justify-content: center;
        picture{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        @include mode("tablet") {
            mask-image: url(brush_spray_02.png) no-repeat cover;
            -webkit-mask-image: url(brush_spray_02.png);
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: cover;
        }

        img {
            width: 80%;
            max-width: 600px;
            user-select: none;
            @include mode('desktop'){
                max-width: 900px;
            }
        }

        // display: none;
    }

    .layer3 {
        background-color: black;
        // display: none;
        mask-image: url("brush_spray_03_mob.webp") no-repeat cover;
        -webkit-mask-image: url("brush_spray_03_mob.webp");
        mask-repeat: no-repeat;
        -webkit-mask-repeat: no-repeat;
        mask-size: cover;
        -webkit-mask-size: cover;

        @include mode("tablet") {
            mask-image: url(brush_spray_03.png) no-repeat cover;
            -webkit-mask-image: url(brush_spray_03.png);
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: cover;
        }
        clip-path: url(#myClipGoto3);
        background-color: darken(#dfe8ed, 0%);
        background-color: lighten(#dfe8ed, 9%);

        // display: none;
    }

    .goto {
        position: fixed;
        width: 100%;
        height: 100vh;
        left: 0;
        top: 0;
        pointer-events: none;
        z-index: 5;
    }
</style>
