<script>
    export let height = 6;
    // export let height = 4;
    export let mirrored = false;
</script>

<svg class="footerLines" class:mirrored={mirrored} class:styleNft={false} viewBox={`0,0,100,${height}`} preserveAspectRatio="none">
    <path d={`M0,${height} L0,4 L100 0, L100,${height}`} />
</svg>

<style type="text/scss">
    @import "../rules/colors.scss";

    svg {
        width: 50%;
        height: 6px;
        position: absolute;
        top: -6px;
        left: 0%;
        path {
            fill: $color-red;
        }
        &.mirrored{
            left: 50%;
            transform: rotateY(180deg);
        }
        &.styleNft{
            path{
                fill:#292a39;
            }
        }
    }
</style>
