<script>
    import { onMount } from "svelte";
    export let text = "";
    export let anim = true;
    export let bannered = false;

    let currentId = Math.floor(Math.random() * 100);

    onMount(() => {
        // id = `b${parseInt(Math.random() * 3000)}`;
        // document.addEventListener(`aos:in:${id}`, ({ detail }) => {
        //     console.log("animated in", detail);
        // });
        document.addEventListener(`aos:in:super${currentId}`, ({ detail }) => {
            if (detail.classList.contains("aos-animate")) {
            } else {
            }
        });
    });
</script>

{#if anim}
    <p data-aos class="paragraphe" data-aos-offset="100" data-aos-id={`super${currentId}`} class:bannered>
        <!-- Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos consequatur quaerat quidem nisi perspiciatis incidunt architecto iusto distinctio eligendi. Qui commodi accusantium nemo iusto dicta ex deleniti maxime fugiat officiis! -->
        <span class="wrap"> <slot /> </span>
        <!-- <span class="choucrout">pipip</span> -->
    </p>
{:else}
    <p class="paragraphe noAnim" class:bannered>
        <!-- Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos consequatur quaerat quidem nisi perspiciatis incidunt architecto iusto distinctio eligendi. Qui commodi accusantium nemo iusto dicta ex deleniti maxime fugiat officiis! -->
        <slot />
        <!-- <span class="choucrout">pipip</span> -->
    </p>
{/if}

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";
    p {
        font-family: "Anton";
        font-weight: 300;
        font-size: 16px;
        transition: all 0.6s ease 0s;
        margin: auto;
        margin-bottom: 30px;
        max-width: 80ch;
        text-align: center;
        font-family: "Didact Gothic";
        font-family: "Oswald";
        font-weight: bold;
        color: #333;

        @include mode("desktop") {
            margin: initial;
            margin-bottom: 30px;
        }

        &.bannered {
            text-align: center;
            @include mode("desktop") {
                text-align: left;
            }
        }

        &.noAnim {
            opacity: 1;
        }

        .wrap {
            display: inline-block;
            opacity: 0;
            clip-path: polygon(0px 0px, 0px 0px, 0px 100%, 0px 100%);
            clip-path: polygon(calc(0px - 100px) 0px, calc(0px - 100px) 0px, 0px 100%, 0px 100%);
            transition: all 0.8s ease 0s;
            color: #333;
        }
    }
    :global(.paragraphe.aos-animate) {
        opacity: 1;
    }
    :global(.paragraphe.aos-animate .highlighting_background) {
        clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%) !important;
        transition: all 0.4s ease 0.7s !important;
    }

    :global(.paragraphe.aos-animate .wrap) {
        clip-path: polygon(
            calc(0px - 100px) 0px,
            100% 0px,
            calc(100% + 100px) 100%,
            calc(0px - 100px) 100%
        ) !important;
        opacity: 1 !important;
    }

    @media (prefers-color-scheme: dark) {
        p {
            font-family: "Anton";
            font-weight: 300;
            font-size: 16px;
            transition: all 0.6s ease 0s;
            margin: auto;
            margin-bottom: 30px;
            max-width: 80ch;
            text-align: center;
            font-family: "Didact Gothic";
            font-family: "Oswald";
            font-weight: bold;
            color: #333;

            @include mode("desktop") {
                margin: initial;
                margin-bottom: 30px;
            }

            &.bannered {
                text-align: center;
                @include mode("desktop") {
                    text-align: left;
                }
            }

            &.noAnim {
                opacity: 1;
            }

            .wrap {
                display: inline-block;
                opacity: 0;
                clip-path: polygon(0px 0px, 0px 0px, 0px 100%, 0px 100%);
                clip-path: polygon(calc(0px - 100px) 0px, calc(0px - 100px) 0px, 0px 100%, 0px 100%);
                transition: all 0.8s ease 0s;
                color: #333;
            }
        }
    }
</style>
