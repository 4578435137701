import { writable } from 'svelte/store';

export const onMenu = writable(false);
export const onGoTo = writable('');
export const onScrollTop = writable(0);
export const onLimit = writable(false);
export const onPlayer = writable(false);
export const skipIntro = writable(false);
export const stopWizard = writable(null);
export const onNft = writable(null);
export const onLevel = writable(null);
export const responsive = writable(null);
export const fullsizeStore = writable(null);