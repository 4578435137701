<script>
    export let lined = false;
</script>

<div class="mainP" class:lined>
    <slot /> <span class="square" />
</div>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";
    .mainP {
        font-family: "Wendy One";
        font-family: "Didact Gothic";
        font-family: "Oswald";

        font-weight: bold;

        // color: white;
        max-width: 380px;
        max-width: 485px;
        position: relative;
        text-align: center;
        margin-bottom: 30px;
        animation: mainPAnim 0.4s ease 0.1s forwards;
        opacity: 0;
        @keyframes mainPAnim {
            0%{opacity: 0; transform: translateX(-10px);}
            100%{opacity: 1;}
        }
        @include mode('desktop'){
            text-align: left;
            font-size: 19px;
        }
        .square {
            display: inline-flex;
            width: 7px;
            height: 7px;
            background-color: #313244;
            position: relative;
            margin-left: 10px;
            &::before {
                content: "";
                position: absolute;
                left: -2px;
                top: -2px;
                background-color: $color-red;
                width: 100%;
                height: 100%;
            }
        }
        &.lined{
            padding-top: 15px;
            padding-left: 20px;
            &::before{
                content:'';
                position: absolute;
                width: 1px;
                height: 100%;
                background-color: $color-red;
                left: 0;
                top: 0;
            }
        }

    }
</style>
