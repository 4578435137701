<script>
    import MaskTitle from "../svg/mask_title.svg";
    import { onMount } from "svelte";
    export let text = "";
    export let color = "";
    export let mini = false;
    export let lined = false;
    export let big = false;
    let id;

    let leftFirstPart = "";
    let leftSecondPart = "";
    let leftThirdPart = "";
    let rightFirstPart = "";
    let rightSecondPart = "";
    let rightThirdPart = "";
    let ended = false;

    const parts = {
        leftFirstPart: leftFirstPart,
        leftSecondPart: leftSecondPart,
        leftThirdPart: leftThirdPart,
    };

    const getPart = (right = false) => {
        let length = text.length;
        if (length > 0) {
            let half = Math.floor(length / 2);
            let leftPart = text.substring(0, half);
            let rightPart = text.substring(half, length);

            let hasSpace = leftPart.at(-1) == " " || rightPart.at(0) == " ";
            leftPart = hasSpace ? `${leftPart}<span class="op">|</span>` : leftPart;
            // console.log({hasSpace});

            return right ? rightPart : leftPart;
        }
    };

    const sleep = (ms) => {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    };

    let currentId = Math.floor(Math.random() * 100);

    onMount(() => {
        document.addEventListener(`aos:in:title${currentId}`, ({ detail }) => {
            if (detail.classList.contains("aos-animate")) {
                test();
            } else {
                ended = false;
            }
        });
    });

    $: {
        if (ended) {
            let playerSound = document.querySelector("#titleSound");
            playerSound.play();
        }
    }

    const gen = async (currentPart = "leftFirstPart") => {
        // for (let index = 0; index < 10; index++) {
        for (let index = 0; index < 8; index++) {
            let lastIndex = 7;
            let genStr = "";
            genStr += `--x:${genX(index == lastIndex)}px;`;
            genStr += `--y:${genY(index == lastIndex)}px;`;
            if (index == 9) {
                genStr += `--opacity:0;`;
            }
            // genStr += `--x:${genX()}px;`;
            // console.log({genStr});
            // let part = parts?.[currentPart];
            // console.log({part})
            // part = genStr;
            // part = part;
            if (currentPart == "leftFirstPart") {
                leftFirstPart = genStr;
            } else if (currentPart == "leftSecondPart") {
                leftSecondPart = genStr;
            } else if (currentPart == "leftThirdPart") {
                leftThirdPart = genStr;
            } else if (currentPart == "rightFirstPart") {
                rightFirstPart = genStr;
            } else if (currentPart == "rightSecondPart") {
                rightSecondPart = genStr;
            } else if (currentPart == "rightThirdPart") {
                rightThirdPart = genStr;
            }

            if (index == lastIndex) {
                ended = true;
            }
            // await sleep(80);
            await sleep(60);
        }
    };

    const genX = (last = false) => {
        if (last) {
            return 0;
        }
        let isNegative = Math.random() < 0.5;
        let value = `${isNegative ? "-" : ""}${Math.floor(Math.random() * 40)}`;
        return value;
    };

    const genY = (last = false) => {
        if (last) {
            return 0;
        }
        let isNegative = Math.random() < 0.5;
        let value = `${isNegative ? "-" : ""}${Math.floor(Math.random() * 10)}`;
        return value;
    };

    // gen();
    // gen("leftSecondPart");
    // gen("leftThirdPart");
    // gen("rightFirstPart");
    // gen("rightSecondPart");
    // gen("rightThirdPart");

    // hello();
    // getPart();
    const test = () => {
        // console.log("youhou");
        gen();
        gen("leftSecondPart");
        gen("leftThirdPart");
        gen("rightFirstPart");
        gen("rightSecondPart");
        gen("rightThirdPart");
    };

    // console.log('if ended')
</script>

<!-- <div class="title" use:reveal> -->
<!-- use:reveal={{ onRevealEnd: test, duration: 0, threshold: 0.5 }} -->
<!-- data-aos -->
<!-- use:reveal={{ onRevealEnd: test, duration: 0, threshold: 0.5 }} -->

<div
    class="title notranslate"
    class:white={color == "white"}
    class:mini
    class:lined
    class:ended
    {id}
    data-aos
    data-aos-offset="100"
    data-aos-id={`title${currentId}`}
>
    <h1 class:big>
        <span class="outfit" />

        <span class="left">
            <div class="svg">
                <!-- <img src="mask_title.svg" alt=""/>  -->
            </div>
            <span class="topPart" style={`--start:0%; --end:33%; ${leftFirstPart}`}> {@html getPart()} </span>
            <span class="secondPart" style={`--start:33%; --end:66%; ${leftSecondPart}`}>
                {@html getPart()}
            </span>
            <span class="thirdPart" style={`--start:66%; --end:100%; ${leftThirdPart}`}>
                {@html getPart()}
            </span>
            <span class="lastPart" class:ended> {@html getPart()} </span>
        </span>
        <span class="right">
            <span class="topPart" style={`--start:0%; --end:33%;  ${rightFirstPart}`}> {getPart(true)} </span>
            <span class="secondPart" style={`--start:33%; --end:66%;  ${rightSecondPart}`}
                >{getPart(true)}</span
            ><span class="thirdPart" style={`--start:66%; --end:100%;  ${rightThirdPart}`}
                >{getPart(true)}</span
            >
        </span>
        <span class="lastPart" class:ended> {getPart(true)} </span>

        <span class="outfit">
            <!-- <img src="mask_title.svg" alt="" /> -->
        </span>
        <slot />
    </h1>
</div>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";
    .title {
        // transform: translateY(-300px);
        white-space: nowrap;
        position: relative;
        opacity: 0;
        transition: opacity 0.2s ease 0s;
        text-align: center;

        :global(&.aos-animate) {
            opacity: 1 !important;
        }
        &::before {
            content: "";
            position: absolute;
        }

        & :global(span.op) {
            opacity: 0;
            user-select: none;
        }

        &.lined {
            h1::after {
                content: "";
                width: 100%;
                height: 1px;
                background-color: $color-red;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                transform-origin: right;
                transform:scaleX(0);
                transition:all 0.3s ease 0s;
                box-shadow: 0px 0px 8px 4px $color-red;

            }
            &.ended  h1::after{
                transform:scaleX(1);
                box-shadow: 0px 0px 0px 0px transparent;
            }
        }

        h1 {
            margin: 0;
            padding: 0;
            display: inline-flex;
            // font-size: 75px;
            font-family: Oswald;
            // font-family: Work Sans;
            color: $color-red;
            position: relative;
            font-size: 28px;
            font-weight: bold;
            @include mode("desktop") {
                font-size: 50px;
                font-size: 40px;
            }

            &.big{
                font-size: 36px;
                @include mode('desktop'){
                    font-size: 60px;
                }
            }
        }
        // font-size: 22px;
        // margin-top: 30px;
        // margin-top: 100px;
        margin-bottom: 30px;
        @include mode("desktop") {
            margin-bottom: 30px;
            text-align: left;

        }

        // font-size: 40px;

        .svg {
            position: absolute;
            height: 100%;
            width: 100px;
            transform: translate3d(calc(-100% - 15px), 22px, 0px);
            & :global(img) {
                width: 100%;
                height: 100px;
            }
        }
        .left,
        .right {
            position: relative;
            span.topPart,
            span.secondPart {
                position: absolute;
                left: 0;
                top: 0;
            }
            span {
                clip-path: polygon(0px var(--start), 100% var(--start), 100% var(--end), 0% var(--end));
            }
        }
        span.topPart,
        span.secondPart,
        span.thirdPart {
            position: absolute;
            color: var(--color, black);
            transform: translate3d(var(--x, 0px), var(--y, 0px), 0px);
            opacity: var(--opacity, 1);
            user-select: none;
        }
        span.lastPart {
            opacity: 1;
            // background: linear-gradient(0deg, black 50%, $color-red 50%, darken($color-red, 5%) 50%);
            // background-size: 100% 200%;
            // background-position: 0px 100%;
            // background-clip: text;
            // -webkit-background-clip: text;
            // color:transparent;
            // animation: title 1s linear 0.9s forwards;
            display: inline-flex;
            opacity: 0;
            &.ended {
                animation: title 1s linear 0s forwards;

                @keyframes title {
                    0% {
                        color: $color-grey;
                        opacity: 1;
                    }
                    10% {
                        color: $color-red;
                        opacity: 1;
                    }
                    13% {
                        color: $color-grey;
                    }
                    15% {
                        color: $color-red;
                    }
                    19% {
                        color: $color-grey;
                    }
                    24% {
                        color: $color-red;
                    }
                    // 26%{ color:darken($color-grey, 15%);}
                    // 30%{ color:lighten($color-red, 10%);}
                    // 40%{ color:$color-red;}
                    100% {
                        color: $color-red;
                        opacity: 1;
                    }
                }
            }
        }
        .outfit {
            img {
                height: 45px;
                margin-left: 20px;
                // display: none;
            }
        }

        &.mini {
            h1 {
                font-size: 30px;
                // font-family: "Wendy One";
                // font-weight: normal;
                padding-bottom: 15px;
                // margin-bottom: 15px;
            }
        }

        &.white {
            span.lastPart {
                &.ended {
                    animation: title2 1s linear 0s forwards;

                    @keyframes title2 {
                        0% {
                            color: $color-grey;
                            opacity: 1;
                        }
                        10% {
                            color: white;
                            opacity: 1;
                        }
                        13% {
                            color: $color-grey;
                        }
                        15% {
                            color: white;
                        }
                        19% {
                            color: $color-grey;
                        }
                        24% {
                            color: white;
                        }
                        // 26%{ color:darken($color-grey, 15%);}
                        // 30%{ color:lighten($color-red, 10%);}
                        // 40%{ color:$color-red;}
                        100% {
                            color: white;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
</style>
