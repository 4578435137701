<script>
    import Paragraphe from "../common/Paragraphe.svelte";
    import Bold from "../common/Bold.svelte";
    import Title from "../common/Title.svelte";
    import BigTitle from "../common/BigTitle.svelte";
    import Line from "../common/Line.svelte";
    import Arrow from "../svg/arrow.svg";
</script>

<div class="story">
    <Title text="LORE" big={true} lined={true} />
    <!-- <BigTitle text="LORE" /> -->

    <div class="content">
        <Paragraphe bannered={true}>
            It doesn’t feel like that long ago when we used the gravite to break the world, but it's been
            almost <Bold important={true}>300</Bold> years since the Upfall. It doesn’t matter that it was an accident,
            that we were trying to save everyone; the Earth is still shattered. We are still living on floating
            remnants so high up that we scrap the void.
        </Paragraphe>

        <Paragraphe bannered={true}>
            Resources are running out. The <Bold>Ordos</Bold>, the <Bold>Altari</Bold> Cult, and the <Bold
                >Freo</Bold
            > Clans struggle over every scrap of inhabitable land, every drop of water, every morsel of food, every
            grain of gravite.
        </Paragraphe>

        <Paragraphe bannered={true}>
            Though it is what doomed us all, the gravite still contains marvels locked within its crystalline
            structure. Marvels that could save us, or could doom us all over again as the three factions use
            it to create powerful weapons in their war for dominance over a broken world.
        </Paragraphe>
    </div>
    <picture class="separator">
        <source media="(min-width: 1024px)" srcset={`highlight_pink.svg`} />
        <img class="separator" src="" alt="separator"/>
    </picture>
    <div class="images">
        <picture>
            <source media="(min-width: 1024px)" srcset={`trace7bis.svg`} />
            <img class="fd" src="" alt="background" data-aos data-aos-offset="100" />
        </picture>
        <picture>
            <source media="(min-width: 1024px)" srcset={`shelter_map.webp`} />
            <img class="desktop-image" src="" alt="Shelter map" data-aos data-aos-offset="300" />
        </picture>
    </div>
</div>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";
    .story {
        padding: 30px;
        max-width: 1248px;
        width: 100%;
        margin: auto;
        position: relative;
        @include mode("desktop") {
            overflow-x: hidden;
        }

        .separator {
            display: none;
            @include mode("desktop") {
                display: block;
                position: absolute;
                height: 2px;
                bottom: 0;
                left: 0;
                width: 100%;
            }
        }

        .images {
            position: relative;
        }
        img {
            width: 100%;
            &.fd {
                display: none;
                @include mode("desktop") {
                    display: block;
                    position: absolute;
                    width: 125%;
                    height: 149%;
                    width: 126%;
                    height: 131%;
                    left: 12%;
                    top: -31%;
                    width: 126%;
                    height: 131%;
                    left: -12%;
                    top: -17%;
                    clip-path: polygon(100% 0px, 100% 0px, 100% 0px);
                    transition: all 0.6s ease 0s;
                    transition-timing-function: steps(16, end);

                    :global(&.aos-animate) {
                        clip-path: polygon(-80% 0%, 100% 0px, 100% 200%) !important;
                    }
                }
            }
        }

        @include mode("desktop") {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 30px;
            padding: 30px 30px 100px;

            & :global(.title) {
                grid-column: 1 / span 2;
            }
        }

        .desktop-image {
            position: relative;
            z-index: 1;
            display: none;
            @include mode("desktop") {
                display: block;
                mask-image: url(vignettage_video.webp);
                -webkit-mask-image: url(vignettage_video.webp);
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                -webkit-mask-size: 100% 100%;
                mask-size: 100% 100%;
                // clip-path: circle(0px);
                margin-top: -15px;
                // clip-path:polygon(0px 0px, 0px 0px, 0px 100%, 0px 100%);
                clip-path: polygon(0px 100%, 0px 100%, 0px 100%);
                transition: transform 0s ease 0.3s, clip-path 0s ease 0.3s, opacity 0.3s ease 0s;
                transition-timing-function: steps(40, end);
                transition-timing-function: steps(40, end);
                // transform: translateX(100px);
                // opacity: 0;
                :global(&.aos-animate) {
                    transform: translateY(0px) !important;
                    opacity: 1 !important;

                    // clip-path: circle(100%) !important;
                    clip-path: polygon(0px 100%, 0px -100%, 200% 100%) !important;

                    transition: clip-path 0.4s ease 0s !important;
                    transition-timing-function: steps(10, end) !important;

                    // clip-path:polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%) !important;
                }
            }
        }
        & :global(.altari) {
            position: absolute;
        }

        & :global(path) {
            fill: rgb(0, 100, 200);
        }

        .goToLore {
            opacity: 0;
            transition: all 0.3s ease 0s;
            font-family: "OSWALD";
            font-weight: bold;
            display: flex;
            align-items: center;
            display: grid;
            grid-template-columns: min-content min-content;
            justify-content: center;
            @include mode("desktop") {
                justify-content: flex-start;
            }
            .content {
                transform: translateX(30px);
                transition: all 0.6s ease 0s;
                display: inline-flex;
                margin-left: 15px;
                white-space: nowrap;
            }
            .arrow {
                animation: arrow 2s linear 0s infinite;
                display: inline-flex;
                @keyframes arrow {
                    0% {
                        transform: translateX(-10px);
                        opacity: 0.8;
                    }
                    50% {
                        transform: translateX(0px);
                        opacity: 1;
                    }
                    100% {
                        transform: translateX(-10px);
                        opacity: 0.8;
                    }
                }
            }
            :global(&.aos-animate) {
                opacity: 1 !important;
            }
            :global(&.aos-animate .content) {
                transform: translateX(0px) !important;
            }
        }
    }

    @media (prefers-color-scheme: dark) {
        .story {
            padding: 30px;
            max-width: 1248px;
            width: 100%;
            margin: auto;
            position: relative;
            @include mode("desktop") {
                overflow-x: hidden;
            }

            .separator {
                display: none;
                @include mode("desktop") {
                    display: block;
                    position: absolute;
                    height: 2px;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                }
            }

            .images {
                position: relative;
            }
            img {
                width: 100%;
                &.fd {
                    display: none;
                    @include mode("desktop") {
                        display: block;
                        position: absolute;
                        width: 125%;
                        height: 149%;
                        width: 126%;
                        height: 131%;
                        left: 12%;
                        top: -31%;
                        width: 126%;
                        height: 131%;
                        left: -12%;
                        top: -17%;
                        clip-path: polygon(100% 0px, 100% 0px, 100% 0px);
                        transition: all 0.6s ease 0s;
                        transition-timing-function: steps(16, end);

                        :global(&.aos-animate) {
                            clip-path: polygon(-80% 0%, 100% 0px, 100% 200%) !important;
                        }
                    }
                }
            }

            @include mode("desktop") {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 30px;
                padding: 30px 30px 100px;

                & :global(.title) {
                    grid-column: 1 / span 2;
                }
            }

            .desktop-image {
                position: relative;
                z-index: 1;
                display: none;
                @include mode("desktop") {
                    display: block;
                    mask-image: url(vignettage_video.webp);
                    -webkit-mask-image: url(vignettage_video.webp);
                    -webkit-mask-repeat: no-repeat;
                    mask-repeat: no-repeat;
                    -webkit-mask-size: 100% 100%;
                    mask-size: 100% 100%;
                    // clip-path: circle(0px);
                    margin-top: -15px;
                    // clip-path:polygon(0px 0px, 0px 0px, 0px 100%, 0px 100%);
                    clip-path: polygon(0px 100%, 0px 100%, 0px 100%);
                    transition: transform 0s ease 0.3s, clip-path 0s ease 0.3s, opacity 0.3s ease 0s;
                    transition-timing-function: steps(40, end);
                    transition-timing-function: steps(40, end);
                    // transform: translateX(100px);
                    // opacity: 0;
                    :global(&.aos-animate) {
                        transform: translateY(0px) !important;
                        opacity: 1 !important;

                        // clip-path: circle(100%) !important;
                        clip-path: polygon(0px 100%, 0px -100%, 200% 100%) !important;

                        transition: clip-path 0.4s ease 0s !important;
                        transition-timing-function: steps(10, end) !important;

                        // clip-path:polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%) !important;
                    }
                }
            }
            & :global(.altari) {
                position: absolute;
            }

            & :global(path) {
                fill: rgb(0, 100, 200);
            }

            .goToLore {
                opacity: 0;
                transition: all 0.3s ease 0s;
                font-family: "OSWALD";
                font-weight: bold;
                display: flex;
                align-items: center;
                display: grid;
                grid-template-columns: min-content min-content;
                justify-content: center;
                @include mode("desktop") {
                    justify-content: flex-start;
                }
                .content {
                    transform: translateX(30px);
                    transition: all 0.6s ease 0s;
                    display: inline-flex;
                    margin-left: 15px;
                    white-space: nowrap;
                }
                .arrow {
                    animation: arrow 2s linear 0s infinite;
                    display: inline-flex;
                    @keyframes arrow {
                        0% {
                            transform: translateX(-10px);
                            opacity: 0.8;
                        }
                        50% {
                            transform: translateX(0px);
                            opacity: 1;
                        }
                        100% {
                            transform: translateX(-10px);
                            opacity: 0.8;
                        }
                    }
                }
                :global(&.aos-animate) {
                    opacity: 1 !important;
                }
                :global(&.aos-animate .content) {
                    transform: translateX(0px) !important;
                }
            }
        }
    }
</style>
