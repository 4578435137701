<script>
    import { responsive } from "../stores/stores.js";
    import { tick } from "svelte";
    export let srcMobile = "";
    export let srcTablet = "";
    export let srcDesktop = "";
    export let withLoader = false;
    export let cls = 0;
    export let withMask = false;
    export let hasFullscreen = false;
    export let trigger = () => {};
    export let onLoad = () => {};

    let loaded = false;
    let videoNode;

    cls = `${cls}px`;
    let responsiveValue;
    let currentSource = null;
    // sync with store value
    responsive.subscribe((val) => {
        responsiveValue = val;
    });

    // console.log({ responsiveValue });
    $: {
        if (responsiveValue) {
            loadVideo();
        }
    }

    const loadVideo = async () => {
        loaded = false;
        currentSource = false;
        await tick();
        if (["m", "t", "d"].includes(responsiveValue)) {
            // console.log('we can load', videoNode);

            let videoSrc = videoNode.getAttribute(`data-${responsiveValue}`);
            let source = document.createElement("source");
            source.src = videoSrc;
            source.type = "video/mp4";
            videoNode.pause();
            videoNode.currentTime = 0;

            if (videoSrc) {
                // console.log("this case", videoSrc);
                currentSource = videoSrc;
            }
            await tick();
            videoNode.onloadeddata = () => {
                // console.log("la video est bien load");
                // videoNode.muted = true;
                setTimeout(() => {
                    loaded = true;
                    onLoad();
                    // videoNode.play();
                }, 0);
            };

            // videoNode.onended = () => {
            // videoNode.play();
            // };

            videoNode.load();
        }
    };
</script>

<div class="videoItem" class:loaded={withLoader && loaded} class:withLoader>
    {#if hasFullscreen}
        <span class="fullscreenIcon"></span>
    {/if}
    <video
        class:cls={!loaded}
        class:hasFullscreen
        class:withMask
        class:loading={!loaded && withLoader}
        style:--cls={cls}
        data-m={srcMobile}
        data-t={srcTablet}
        data-d={srcDesktop}
        bind:this={videoNode}
        on:click={trigger}
        autoplay
        loop
        muted
        playsinline
    >
        {#if currentSource}
            <source src={currentSource} type="video/mp4" />
        {/if}
    </video>
    {#if withLoader}
        <div class="loader" />
    {/if}
</div>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";
    .videoItem {
        position: relative;

        .fullscreenIcon{
            display: none;
            pointer-events: none;
            @include mode('desktop'){
                display: block;
                width: 40px;
                height: 40px;
                position: absolute;
                background: url('./button_fullscreen_white.webp');
                background-size: cover;
                z-index: 2;
                top: 15px;
                right: 10px;
                transition: all 0.2s ease 0s;
                transform:scale(1.2);
                // transition-timing-function: steps(10);
                opacity: 0;
            }
        }

        &:hover{
            .fullscreenIcon{
                opacity: 1;
                transform:scaleX(1);
            }
        }

        video {
            cursor: pointer;
            width: 100%;
            &.withMask {
                mask-image: url("vignettage_video_mob.webp");
                -webkit-mask-image: url("vignettage_video_mob.webp");
                -webkit-mask-repeat: no-repeat;
                mask-repeat: no-repeat;
                -webkit-mask-size: 100% 100%;
                mask-size: 100% 100%;
                @include mode("tablet") {
                    mask-image: url(vignettage_video.webp);
                    -webkit-mask-image: url(vignettage_video.webp);
                }
            }
        }

        video::-webkit-media-controls {
            display: none !important;
        }

        .cls {
            min-height: var(--cls);
        }

        .loader {
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            z-index: 1;
            transition: all 0.2s ease 0s;
            pointer-events: none;
            // display: none;
            &::before,
            &::after {
                content: "";
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                position: absolute;
            }

            &::before {
                width: 30px;
                height: 30px;
                border: solid 4px transparent;
                border-top: solid 4px $color-red;
                border-left: solid 4px $color-red;
                border-radius: 50%;
                animation: loader 1s linear infinite;

                @keyframes loader {
                    0% {
                        transform: rotate(0deg);
                    }
                    50% {
                        transform: rotate(180deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
            }

            &::after {
                width: 20px;
                height: 20px;
                border: solid 4px transparent;
                border-top: solid 4px $color-grey;
                border-left: solid 4px $color-grey;
                border-radius: 50%;
                animation: loader2 1.3s linear infinite;

                @keyframes loader2 {
                    0% {
                        transform: rotate(0deg);
                    }
                    50% {
                        transform: rotate(180deg);
                    }
                    100% {
                        transform: rotate(360deg);
                    }
                }
            }
        }

        &.withLoader {
            video {
                -webkit-clip-path: circle(0px);
                clip-path: circle(0px);
                transition: all 1s ease 0s;
                -webkit-transition: all 1s ease 0s;
            }

            &.loaded {
                .loader {
                    opacity: 0;
                }
                video {
                    clip-path: circle(100%);
                    -webkit-clip-path: circle(100%);
                    &.hasFullscreen{

                    }
                }
            }
        }
    }
</style>
