<script>
    /*------------------------------*"
    - INIT
    \*------------------------------*/

    const currentDate = () => new Date()?.getFullYear();
</script>

<div class="flexer copyright" data-aos data-aos-offset="0">
    <div class="leftOutfit">
        <div class="currentLine" />
        <img src="./build/footerLeft.webp" alt="Footer" class="left" />
        <img src="./group7left.webp" alt="Footer" class="leftTop" />
    </div>
    <span class="license"> Licensed By WeAbove </span>
    <div class="rightOutfit">
        <div class="currentLine" />
        <img src="./build/footerLeft.webp" alt="Footer" class="right" />
        <img src="./group7right.webp" alt="Footer" class="rightTop" />
    </div>
</div>

<div class="andCopy">
    <img class="mirror" src="crystal_grey.svg" alt="" /> <span>&copy; WEABOVE {currentDate()}</span>
    <img src="crystal_grey.svg" alt="" />
</div>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";
    .copyright {
        margin-top: 10px;
        position: relative;
        @include mode("desktop") {
            margin-top: 0px;
        }

        .left,
        .leftTop,
        .right,
        .rightTop {
            position: absolute;
        }
        .left {
            left: 0;
        }

        .right {
            right: 0;
        }

        .left,
        .right {
            width: 100%;
            bottom: -3px;
            @include mode("desktop") {
                bottom: 0;
            }
        }

        .leftTop {
            left: 0;
        }

        .rightTop {
            right: 0;
        }

        .leftTop,
        .rightTop {
            bottom: 0;
            width: 60px;
            @include mode("desktop") {
                bottom: 10px;
                width: 100px;
            }
        }

        .license {
            text-align: center;
            font-family: "OSWALD";
            font-weight: bold;
            color: white;
            @include mode("desktop") {
                color: $color-grey;
            }
        }
    }
    .flexer {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: center;
        column-gap: 30px;
        clip-path: polygon(50% 0px, 50% 0px, 50% 100%, 50% 100%);
        transition: all 0.6s ease 0.1s;
        opacity: 0;
        :global(&.aos-animate) {
            opacity: 1 !important;
            clip-path: polygon(0% -100px, 100% -100px, 100% 100%, 0% 100%) !important;
        }
        .leftOutfit,
        .rightOutfit {
            width: 100%;
            height: 1px;
            display: flex;
            position: relative;
            .currentLine {
                height: 1px;
                width: 100%;
                background-color: $color-red;
            }
        }
    }

    .andCopy {
        color: $color-red;
        font-family: "Oswald";
        font-weight: bold;
        min-height: 40px;
        align-items: center;
        justify-content: center;
        display: grid;
        grid-template-columns: min-content auto min-content;
        column-gap: 15px;
        img{
            width: 20px;
            &.mirror{
                transform:rotateY(180deg);
            }
        }
        @include mode("desktop") {
            min-height: 50px;
        }
    }
</style>
