<script>
    /*------------------------------*"
	- INITIALISATION
	\*------------------------------*/

    import { onMount } from "svelte";
    import Menu from "./menu/Menu.svelte";
    import Intro from "./intro/Intro.svelte";
    import Overlay from "./overlay/Overlay.svelte";
    import Footer from "./footer/Footer.svelte";
    import Player from "./player/Player.svelte";
    import Goto from "./goto/Goto.svelte";
    import Banner from "./banner/banner.svelte";
    import Team from "./team/Team.svelte";
    import Realnft from "./Realnft/Realnft.svelte";
    import Faq from "./faq/Faq.svelte";
    import Roadmap from "./roadmap/Roadmap.svelte";
    import { onScrollTop, onLimit, stopWizard, onLevel, onGoTo, responsive } from "./stores/stores";
    import Story from "./story/Story.svelte";
    import Extras from "./extras/Extras.svelte";
    import Exploitation from "./Exploitation/Exploitation.svelte";
    import Fullsize from "./fullsize/Fullsize.svelte";
    import Service from "./service/Service.svelte";
    import AOS from "aos";
    let nftContainer;

    let y;

    let backgroundY = "";
    let confirmAccess = null;
    let road = 400;
    let scroller;

    let responsiveValue;
    // sync with store value
    responsive.subscribe((val) => {
        responsiveValue = val;
    });

    $: {
        if (responsiveValue == "t" || responsiveValue == "d") {
            let body = document.body,
                html = document.documentElement;

            let height = Math.max(
                body.scrollHeight,
                body.offsetHeight,
                html.clientHeight,
                html.scrollHeight,
                html.offsetHeight
            );

            let ratio = road / height;
            backgroundY = `-${ratio * y}px`;
        }
    }

    $: {
        if (responsiveValue == "t" || responsiveValue == "d") {
            // console.log("we there y");
            document.body.style.setProperty("--bgy", backgroundY);
            document.body.style.setProperty("--road", `${road}px`);
        }
    }

    $: {
        if (responsiveValue == "t" || responsiveValue == "d") {
            let TeamContainer = document.querySelector(".team");

            if (nftContainer) {
                let nftOffset = nftContainer.offsetTop;
                let windowHeight = window.innerHeight;
                if (y > nftOffset - windowHeight) {
                    updateStopWizard(nftOffset - windowHeight);
                } else {
                    updateStopWizard(null);
                }
            }

            if (nftContainer && y > nftContainer.offsetTop && y < TeamContainer.offsetTop) {
                updateOnLevel(true);
            } else {
                updateOnLevel(false);
            }
        }
    }

    onMount(() => {
        nftContainer = document.querySelector(".realnft");

        AOS.init();
        let cookies = document.cookie;
        // console.log({cookies});
        if (cookies) {
            confirmAccess = true;
        } else {
            confirmAccess = false;
        }
    });

    const updateScroll = () => {
        // console.log("youyou a", window.scrollY);
        y = window.scrollY;
    };

    $: {
        if (responsiveValue == "t" || responsiveValue == "d") {
            window.addEventListener("scroll", updateScroll);
        } else {
            window.removeEventListener("scroll", updateScroll);
        }
    }

    let scrollTopValue;
    let onGoToValue;

    let limit;

    onScrollTop.subscribe((val) => {
        scrollTopValue = val;
    });

    onGoTo.subscribe((val) => {
        onGoToValue = val;
    });

    onLimit.subscribe((val) => {
        limit = val;
    });

    const updateStopWizard = (value = null) => {
        stopWizard.update((n) => value);
    };

    const updateOnLevel = (value = null) => {
        onLevel.update((n) => value);
    };
</script>

<Service />

{#if confirmAccess === false}
    <Intro />
{:else if confirmAccess === true}
    <div class="bg" />
    <div class="website">
        <Goto />
        <Menu />

        <div class="content" class:growing={onGoToValue != ""}>
            <!--STRUCTURE-->
            <Fullsize />
            <Overlay />
            <Banner />
            <Story />
            <Realnft />
            <Exploitation />
            <Roadmap/>
            <!-- <Extras /> -->
            <Team />
            <Faq />
            <Player />
            <Footer />
        </div>

        <video id="titleSound" controls>
            <source src="sound-title.mp3" />
        </video>
    </div>
{/if}

<style type="text/scss">
    @import "./rules/mixins.scss";
    @import "./rules/colors.scss";

    :root {
        color-scheme: light; /* both supported */
    }

    .bg {
        width: 100vw;
        height: 100vh;
        // height: calc(100vh + var(--road, 400px));
        transform: translateY(0px);
        background-color: orange;
        position: fixed;
        background: url("./bg.webp") no-repeat;
        background-size: cover;
        background-attachment: fixed;
        width: 100%;
        overflow-x: hidden;
        z-index: 1;
        // background-position: 0px var(--bgy, var(--road, 400px));
        // background-position: 0px var(--bgy, var(-400px, 400px));
        @include mode("tablet") {
            height: calc(100vh + var(--road, 400px));
            background: url("../bg.svg") no-repeat;
            background-size: cover;
            background-attachment: fixed;
            width: 100%;
            overflow-x: hidden;
            background-position: 0px var(--bgy, var(--road, 400px));
        }
    }
    :global(*) {
        box-sizing: border-box;
    }
    :global(div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video) {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    #titleSound {
        display: none;
    }
    .group {
        transition: transform 1s cubic-bezier(0.41, -0.38, 0.73, 0.89) 0s;
        transition: transform 1s cubic-bezier(0.8, -0.4, 0.73, 0.89) 0s;

        &.skip {
            transform: translateX(-50%);
        }
    }
    .righter {
        display: flex;
        // flex-flow: ;
        width: 100%;
        z-index: 1;
        position: relative;
        & > div {
            width: 50%;
        }

        & > div:first-child {
            display: none;
            @include mode("desktop") {
                display: inline-flex;
            }
        }
        & > div:last-child {
            width: 100%;
            @include mode("desktop") {
                width: 50%;
                padding-right: 30px;
            }
        }
    }

    .limit {
        width: 100%;
        position: relative;
        z-index: 1;

        .limitOverlay {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: $color-grey;
            clip-path: circle(0%);
            transition: 2s ease 0s;
        }

        .realnftContainer {
            width: 100%;
            margin: auto;
            background-color: $color-grey;
            min-height: 50vh;
            padding-top: 150px;

            .container {
                max-width: 1428px;
                margin: auto;
                display: grid;
                grid-template-columns: 4fr 1fr;
                grid-template-columns: 4fr;
                .right {
                    border: solid 4px brown;
                    color: white;
                    display: none;
                }

                .left {
                    width: 100%;
                    min-height: 300px;
                }
            }
        }
    }

    :global(html, body) {
        padding: 0;
        margin: 0;
    }

    :global(button) {
        margin: 0;
    }

    :global(*) {
        box-sizing: border-box;
    }

    :global(.outsideView) {
        position: fixed;
        left: -999px;
    }

    .newsletter {
        z-index: 6;
        position: fixed;
        right: 90px;
        top: 20px;
        top: 56px;
        width: 25px;
        height: 25px;
        background-color: green;
        background-color: #c6c7dc;
        .first {
            background-color: lightblue;
            width: 30px;
            height: 30px;
            position: absolute;
            left: 5px;
            top: 5px;
            background-color: #73769d;
        }

        .second {
            background-color: rgb(29, 95, 117);
            background-color: #adcdd7;
            box-shadow: 3px 3px 2px 0px rgb(20, 95, 120);
            width: 50px;
            height: 50px;
            position: absolute;
            left: 15px;
            top: 15px;
            left: 11px;
            top: 12px;
            background-color: #313244;
            box-shadow: 3px 3px 2px 0px #f4f8f9;
        }
    }
    .website {
        width: 100%;
        position: relative;
        z-index: 2;

    }

    .content {
        transition: all 1s ease 0s;
        &.growing {
            opacity: 0;
        }
    }

</style>
