<script>
    export let grey = false;
    export let strong = false;
</script>

<span class="highlighting">
    <span class="highlighting_background" class:grey class:strong></span>
    <span class="content" class:strong> <slot></slot> </span>
</span>

<style type="text/scss">
    .highlighting{
        display: inline-flex;
        padding: 5px;
        white-space: nowrap;
        position: relative;
        .highlighting_background{
            position: relative;
            display: inline-flex;
            position: absolute;
            background-color: black;
            // background: url('highlight_pink.png') no-repeat;
            background: url('highlight_pink.webp') no-repeat;
            background-size: 100% 100%;
            z-index: 1;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            clip-path: polygon(0px 0px, 0px 0px, 0px 100%, 0px 100%);
            transition:all 0s ease 0.4s;
            &.grey{
                background: url('trait3.png') no-repeat;
                background-size: 100% 100%;
            }
            &.strong{
                background: url('new_highlight.png') no-repeat;
                background-size: 100% 100%;
            }
        }

        .content{
            position: relative;
            z-index: 2;
            &.strong{
                padding: 5px;
            }
        }
    }
</style>