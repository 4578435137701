<script>
    import { onMount } from "svelte";
    import { responsive } from "../stores/stores.js";

    /*------------------------------*"
    - RESPONSIVE
    \*------------------------------*/

    // var to sync
    let modeValue;

    // sync with store value
    responsive.subscribe((val) => {
        modeValue = val;
    });

    // update store value
    const updateResponsive = (val = "") => {
        responsive.update((n) => val);
    };

    // resize screen
    const resize = (width) => {
        if (width < 768) {
            modeValue != "m" && updateResponsive("m");
        } else if (width < 1024) {
            modeValue != "t" && updateResponsive("t");
        } else if (width >= 1024) {
            modeValue != "d" && updateResponsive("d");
        }
    };

    // trigger
    onMount(() => {
        let width = window.innerWidth;
        resize(width);
        window.addEventListener("resize", (e) => {
            let width = e.target.innerWidth;
            resize(width);
        });
    });
</script>
