<script>
    import BigTitle from "../common/BigTitle.svelte";
    import FaqItem from "./FaqItem.svelte";
</script>

<div class="faq" data-aos data-aos-offset="200">
    <BigTitle text="FAQ" />
    <div class="faqGrid">
        <FaqItem question="What is the project WeAbove ?" fromLeft={false} index=0>
            WeAbove is an NFT collection conceived and created by world-renowned artists specialized in 2D and
            3D animation. Follow the exciting story of 3 factions (Ordos, Altari, Freo) trying to survive in a
            world where gravity has been shaken by a powerful mineral.
        </FaqItem>
        <!-- <span class="highlight1">3 factions</span -->

        <FaqItem question="Who is behind the project ?" index=1>
            The crew is made up of experts at all levels. The 6 artists have all worked on the wonderful
            animation series Arcane (Netflix). The marketing crew is made up of digital experts, investors and
            collectors of NFTs. The Community Management crew is specialize in Twitter and works for Le
            Journal du Coin. Visit the section : TEAM
        </FaqItem>

        <FaqItem question="Is there any affiliation with another project ?" fromLeft={false} index=2>
            WeAbove is an independent project made by artists in their spare time. It is not affiliated with
            any other production company.
        </FaqItem>

        <FaqItem question="Where can I join the community ?" index=3>
            <ul>
                <li>Discord : <a target="_blank" rel="noopener noreferrer" href="https://discord.gg/weabove">https://discord.gg/weabove </a></li>
                <li>Twitter : <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/weaboveofficial">https://twitter.com/weaboveofficial</a></li>
            </ul>
        </FaqItem>

        <FaqItem question="On which blockchain will it be launched?" fromLeft={false} index=4>
            The project will be launched on the ethereum blokchain (ETH)
        </FaqItem>

        <FaqItem question="Where can I buy my NFT ?" index=5>
            The url will be communicated a few days before the mint to avoid any scamming attempts.
        </FaqItem>

        <FaqItem question="How many NFT will be sold ?" fromLeft={false} index=6>
            The exact number of NFTs is 8751, at 0.15 ETH each.
        </FaqItem>

        <FaqItem question="When is the mint date ?" fromLeft={false} index=8>The date of the mint is September 8, 2022.</FaqItem>
    </div>
</div>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";

    .faq {
        max-width: 1248px;
        width: 100%;
        margin: auto;
        padding-top: 30px;
        padding-bottom: 30px;
        overflow-x: hidden;
        @include mode('desktop'){
            // margin-top: 100px;
            margin-top: 40px;
        }

        // mask-image: url(trace_pink5.png) no-repeat cover;
        // -webkit-mask-image: url(trace_pink5.png);
        // -webkit-mask-repeat: no-repeat;
        // -webkit-mask-position: -10px 0px;
        // -webkit-mask-size: cover;

        .faqGrid {
       
            column-gap: 15px;
            row-gap: 15px;
            @include mode("desktop") {
                column-gap: 30px;
                row-gap: 30px;
                // padding-bottom: 60px;
                // padding-bottom: 120px;
                // padding-top: 126px;
            }
            & > div:last-child {
                display: none;
            }
        }
    }
</style>
