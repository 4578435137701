<script>
    import Typewriter from "svelte-typewriter";
    export let text = "";
    export let author = "";
    let ended = false;

    text = `“ ${text} ”`;

    /*------------------------------*"
    - FUNCTIONNAL
    \*------------------------------*/
</script>

<blockquote class="citation">
    <div class="text">
        <Typewriter
            interval={25}
            on:done={() => {
                ended = true;
            }}>{text}</Typewriter
        >
    </div>
    <div class="author" class:on={ended}>
        - {author}
    </div>
</blockquote>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";

    .citation {
        max-width: 450px;
        max-width: 485px;
        font-style: italic;
        font-size: 15px;
        width: 100%;

        .text {
            color: grey;
            margin-bottom: 8px;
            text-align: center;
            :global(& p) {
                width: 100%;
                min-height: 60px;
            }
            @include mode("desktop") {
                text-align: left;
                font-size: 17px;
                margin-bottom: 12px;
            }
        }
        .author {
            position: relative;
            color: grey;
            color: transparent;
            text-align: right;
            padding: 0px 10px 5px;
            transition: all 0.2s ease 0.4s;
            // opacity: 0;
            // transform: translateX(10px);

            &::before {
                content: "";
                position: absolute;
                width: 100px;
                width: 50%;
                height: 1px;
                right: 0;
                bottom: 0;
                background-color: $color-red;
                transform:scaleX(0);
                transition: all 0.2s ease 0.2s;
                transform-origin: right;

            }
            &::after {
                content: "";
                position: absolute;
                width: 1px;
                height: 100%;
                right: 0;
                bottom: 0;
                background-color: $color-red;
                transform:scaleY(0);
                transition: all 0.2s ease 0s;
                transform-origin: top;
            }

            &::before, &::after{
                // opacity: 0;
            }


            &.on {
                color:#181818;
                &::before{
                    transform:scaleX(1);
                    opacity: 1;
                }
                &::after{
                    transform:scaleY(1);
                    opacity: 1;
                }
            }
        }
    }
</style>
