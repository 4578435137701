<script>
    import Title from "../common/Title.svelte";
    import BigTitle from "../common/BigTitle.svelte";
    import TeamItem2 from "./TeamItem2.svelte";
    import { onMount } from "svelte";
    import Developed from "../common/Developed.svelte";
    import TitleB from "../common/TitleB.svelte";

    const lines = {
        line1: { start: "M0,0 L0,0 L0,30 L0,30 Z", end: "M0,0 L360,0 L360,30 L0,30 Z" },
        line2: { start: "M360,20 L360,20 L400,40 L400,40 Z", end: "M360,20 L0,30 L0,60 L400,40 Z" },
        line3: { start: "M0,40 L0,40 L0,100 L0,100 Z", end: "M0,40 L360,40 L360,100 L0,100 Z" },
    };

    let showed = false;

    $: getLine = (line = 1) => {
        let from = !showed ? "start" : "end";
        return lines?.[`line${line}`]?.[from];
    };

    onMount(() => {
        document.addEventListener(`aos:in:coreteam`, ({ detail }) => {
            if (detail.classList.contains("aos-animate")) {
                // console.log('show');
                showed = true;
            } else {
                // console.log('hide');
                showed = false;
            }
        });
    });
</script>

<div class="team">
    <div class="w_team">
        <BigTitle text="CORE TEAM" white={true} />
        <div class="c_team" data-aos data-aos-offset="200" data-aos-id={`coreteam`}>
            <TeamItem2
                name="Aurel"
                background="1"
                role="2D FX"
                roleInfo="Lead 2D FX on Arcane S1&S2"
                twitter="https://www.linkedin.com/in/aur%C3%A9lien-ressencourt-b6805877/"
                instagram="https://www.instagram.com/aurelien_ressencourt/"
            />
            <TeamItem2
                name="Lad"
                background="2"
                role="Character Modeling"
                roleInfo="Lead Character Modeling on Arcane S1&S2"
                twitter="https://www.linkedin.com/in/ladislasgueros/"
                instagram="https://www.instagram.com/ladislasgueros"
                artstation="https://www.artstation.com/lad"
            />
            <TeamItem2
                name="Ed"
                background="3"
                role="Tool Engine"
                roleInfo="Rigging on Arcane S1"
                twitter="https://www.linkedin.com/in/edgard-cros/"
            />

            <TeamItem2
                name="Nico"
                background="5"
                role="Modeling & Graphisme"
                roleInfo="Supervisor Asset on Arcane S2"
                twitter="https://www.linkedin.com/in/ndaegelen/"
            />
            <TeamItem2
                name="Sim"
                background="6"
                role="Textures & Illustrations"
                roleInfo="Texture Artist on Arcane S1&S2"
                twitter="https://www.linkedin.com/in/sgoeneuttel/"
                instagram="https://www.instagram.com/simon_goeneutte_lefevre/"
                artstation="https://www.artstation.com/simonweasel"
            />
            <TeamItem2
                name="Mika"
                background="7"
                role="Light & Compositing"
                roleInfo="Lead Compositing on Arcane S1"
                twitter="https://www.linkedin.com/in/mika-bellamy/"
                instagram="https://www.instagram.com/mika.minis/"
            />
            <TeamItem2
                name="Romp"
                background="8"
                role="Head of Marketing"
                roleInfo="Digital & gaming specialist"
                twitter="https://www.linkedin.com/in/romainpedreno/"
            />
            <svg class="mask" viewBox="0 0 100 100" preserveAspectRatio="none">
                <clipPath id="myClip" clipPathUnits="objectBoundingBox" transform="scale(0.003033 0.0116279)">
                    <path class="test2" d={getLine(1)} style:--time="0.4s" style:--delay="0s" />
                    <path class="test3" d={getLine(2)} style:--time="0.4s" style:--delay="0.4s" />
                    <path class="test4" d={getLine(3)} style:--time="0.4s" style:--delay="0.8s" />
                </clipPath>
            </svg>
        </div>

        <Title text="TEAM" lined={true} />

        <div class="teamBlock" data-aos data-aos-offset="200">
            <span>
                <span class="name">Alexandre Mahboubi </span> <span class="separator"> /</span>
                <span class="red"
                    >Illustrator & Character Designer on Arcane
                    <div class="socials">
                        <a
                            href="https://www.artstation.com/alex-mabb"
                            target="_blank"
                            rel="noopener noreferrer"><img src="/logo_artstation_mini.webp" alt="logo" /></a
                        >
                        <a
                            href="https://www.instagram.com/al.mabb/?hl=fr"
                            target="_blank"
                            rel="noopener noreferrer"><img src="/logo_instagram_mini.webp" alt="logo" /></a
                        >
                        <a
                            href="https://twitter.com/alexandremahbo1"
                            target="_blank"
                            rel="noopener noreferrer"><img src="/logo_twitter_mini.webp" alt="logo" /></a
                        >
                    </div>
                </span>
            </span>

            <span
                ><span class="name">Samuel "The Archivist" </span> <span class="separator"> /</span>
                <span class="red">Senior Game Designer</span>
                <div class="socials">
                    <a href="https://twitter.com/TheArch71913313" target="_blank" rel="noopener noreferrer"
                        ><img src="/logo_twitter_mini.webp" alt="logo" /></a
                    >
                </div>
            </span>
            <span
                ><span class="name">Edouard "EasyLife" </span> <span class="separator"> /</span>
                <span class="red">Twitter Master & redactor Le Journal du Coin</span>
                <div class="socials">
                    <a href="https://twitter.com/Easylife_Crypto" target="_blank" rel="noopener noreferrer"
                        ><img src="/logo_twitter_mini.webp" alt="logo" /></a
                    >
                </div>
            </span>
            <span
                ><span class="name">Thomas "CryptOcelot" </span> <span class="separator"> /</span>
                <span class="red"
                    >Twitter Master & redactor Le Journal du Coin

                    <div class="socials">
                        <a href="https://twitter.com/Crypt0celot" target="_blank" rel="noopener noreferrer"
                            ><img src="/logo_twitter_mini.webp" alt="logo" /></a
                        >
                    </div>
                </span></span
            >
            <span
                ><span class="name">Brice "NevaVerse" </span> <span class="separator"> /</span>
                <span class="red"
                    >Discord Architect

                    <div class="socials">
                        <a href="https://twitter.com/NevaVerse" target="_blank" rel="noopener noreferrer"
                            ><img src="/logo_twitter_mini.webp" alt="logo" /></a
                        >
                    </div>
                </span></span
            >

            <span
                ><span class="name">Logan "Logeon" </span> <span class="separator"> / </span>
                <span class="red"
                    >Web Graphist

                    <div class="socials">
                        <a href="https://twitter.com/log_eon" target="_blank" rel="noopener noreferrer"
                            ><img src="/logo_twitter_mini.webp" alt="logo" /></a
                        >
                    </div>
                </span></span
            >
            <span
                ><span class="name">Joseph Colban </span> <span class="separator"> / </span>
                <span class="red"
                    >2D Illustrator
                    <div class="socials">
                        <a href="https://www.instagram.com/go_zeph/?hl=fr" target="_blank" rel="noopener noreferrer"
                            ><img src="/logo_instagram_mini.webp" alt="logo" /></a
                        >
                    </div>
                </span>
            </span>
        </div>

        <TitleB text="Developer" />

        <Developed fullname="Warren N." />
    </div>
</div>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";

    h1 {
        text-align: center;
    }
    svg.mask {
        width: 100%;
        position: fixed;
        left: -999px;

        path.test2 {
            transition: all 0s ease 0.4s;
            -webkit-transition: all 0s ease 0.4s;
        }
        path.test3 {
            transition: all 0s ease 0.4s;
            -webkit-transition: all 0s ease 0.4s;
        }
        path.test4 {
            transition: all 0s ease 0.4s;
            -webkit-transition: all 0s ease 0.4s;
        }
    }
    .team {
        padding: 30px 30px 0px;
        // min-height: 100vh;
        position: relative;
        z-index: 1;

        & :global(.bigTitle) {
            background-color: transparent !important;
        }

        & :global(.paragraphe) {
            margin: auto;
            margin-bottom: 30px;
            text-align: center;
        }

        & :global(.title) {
            // max-width: 65ch;
            margin: auto;
            text-align: center !important;
        }

        .teamBlock {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 30px;

            font-family: "Didact Gothic";
            opacity: 0;
            & > span {
                margin-bottom: 15px;
            }
            img {
                width: 16px;
            }
            span {
                font-weight: bold;
                text-align: center;
            }
            & > span {
                display: grid;
                justify-content: center;
                grid-template-columns: 1fr;
                @include mode("desktop") {
                    display: initial;
                }
            }
            .socials {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                margin-left: 5px;
                margin-right: 5px;
                column-gap: 6px;
                img {
                    width: 20px;
                    vertical-align: middle;
                }
            }
            span.name {
                // opacity: 0;
                transform: translateX(-30px);
                transition: all 0.5s ease 0s;
                display: inline-grid;

                img {
                    width: 20px;
                    vertical-align: middle;
                }
                @include mode("desktop") {
                    display: inline-flex;
                    align-items: center;
                }
            }
            span.red {
                color: $color-red;
                opacity: 0;
                transform: translateX(30px);
                display: inline-grid;
                transition: opacity 0.5s ease 0s, transform 0.5s ease 0s;
                @include mode("desktop") {
                    display: inline-flex;
                    white-space: nowrap;
                }
            }

            span.separator {
                display: none;
                @include mode("desktop") {
                    display: inline-flex;
                }
            }

            :global(&.aos-animate) {
                opacity: 1 !important;
            }
            :global(&.aos-animate span.red) {
                // background-position: 0% 0px !important;
                opacity: 1 !important;
                transform: translateX(0px) !important;
                transition: opacity 0.5s ease 0s, transform 0.5s ease 0s;
            }

            :global(&.aos-animate span.name) {
                opacity: 1 !important;
                transform: translateX(0px) !important;
            }
        }

        .w_team {
            max-width: 1248px;
            margin: auto;
            margin-bottom: 60px;
            // margin-bottom: 30px;

            .c_team {
                display: grid;
                grid-auto-flow: dense;
                grid-template-columns: repeat(2, 1fr);
                row-gap: 30px;
                margin: auto;
                column-gap: 30px;
                margin-top: 30px;
                margin-bottom: 30px;
                max-width: 550px;

                :global(&.aos-animate path.test2) {
                    transition: d 0.4s ease 0s !important;
                }

                :global(&.aos-animate path.test3) {
                    transition: d 0.4s ease 0.4s !important;
                }

                :global(&.aos-animate path.test4) {
                    transition: all 0.4s ease 0.8s !important;
                }

                @include mode("desktop") {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    column-gap: 60px;
                    width: 100%;
                    row-gap: 70px;
                    margin-top: 60px;
                    margin-bottom: 75px;
                    max-width: initial;
                }
            }
        }
    }
</style>
