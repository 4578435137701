<script>
    export let background = 2;
    export let name = "";
    export let role = "FX";
    export let roleInfo = "Load FX 2D on Arcane";
    export let twitter = "";
    export let instagram = null;
    export let artstation = null;
    const getNameLower = (str = "") => str.toLowerCase();
</script>

<div class="teamItem2">
    <div class={`teamItem2--wrap bg${background}`}>
        <picture>
            <source media="(max-width: 500px)" srcset={`${getNameLower(name)}_tease_180.webp`} />
            <source media="(max-width: 1024px)" srcset={`${getNameLower(name)}_tease_400.webp`} />
            <source media="(min-width: 1024px)" srcset={`${getNameLower(name)}_tease_300.webp`} />
            <img src={`${getNameLower(name)}_tease.webp`} alt={`${name} picture`} />
        </picture>
        <span class="currentName notranslate">{name}</span>
    </div>
    <div class="name">
        <div class="info">
            <div class="role">{role}</div>
            <div class="roleInfo">{roleInfo}</div>
            <div class="twitter">
                <a href={twitter} target="_blank" rel="noopener noreferrer"> <img src="linkedin.svg" /> </a>
                {#if instagram}
                    <a href={instagram} target="_blank" rel="noopener noreferrer"> <img src="logo_instagram_mini.webp" /> </a>
                {/if}
                {#if artstation}
                    <a href={artstation} target="_blank" rel="noopener noreferrer"> <img src="logo_artstation_mini.webp" /> </a>
                {/if}
            </div>
        </div>
    </div>
</div>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";

    .teamItem2 {
        text-align: center;
        position: relative;
        transition: all 0.2s ease 0s;
        opacity: 0;
        transition: opacity 0.4s ease 0s;

        :global(.c_team.aos-animate &) {
            transition: opacity 0s ease 0s;
            opacity: 1 !important;
        }

        .teamItem2--wrap {
            mask-image: url("fond_PP_08_mob.webp");
            -webkit-mask-image: url("fond_PP_08_mob.webp");
            mask-repeat: no-repeat;
            -webkit-mask-repeat: no-repeat;
            mask-position: 0px 0px;
            -webkit-mask-position: 0px 0px;
            mask-size: 100% 100%;
            -webkit-mask-size: 100% 100%;

            @include mode("tablet") {
                mask-image: url("fond_PP_08.webp");
                -webkit-mask-image: url("fond_PP_08.webp");
                mask-repeat: no-repeat;
                -webkit-mask-repeat: no-repeat;
                mask-position: 0px 0px;
                -webkit-mask-position: 0px 0px;
                mask-size: 100% 100%;
                -webkit-mask-size: 100% 100%;
            }

            clip-path: url(#myClip);

            img {
                vertical-align: bottom;
                width: 100%;
            }

            &.bg2 {
                mask-image: url("fond_PP_02_mob.webp");
                -webkit-mask-image: url("fond_PP_02_mob.webp");
                @include mode("tablet") {
                    mask-image: url("fond_PP_02.webp");
                    -webkit-mask-image: url("fond_PP_02.webp");
                }
            }
            &.bg3 {
                mask-image: url("fond_PP_03_mob.webp");
                -webkit-mask-image: url("fond_PP_03_mob.webp");
                @include mode("tablet") {
                    mask-image: url("fond_PP_03.webp");
                    -webkit-mask-image: url("fond_PP_03.webp");
                }
            }
            &.bg4 {
                mask-image: url("fond_PP_04_mob.webp");
                -webkit-mask-image: url("fond_PP_04_mob.webp");
                @include mode("tablet") {
                    mask-image: url("fond_PP_04.webp");
                    -webkit-mask-image: url("fond_PP_04.webp");
                }
            }
            &.bg5 {
                mask-image: url("fond_PP_05_mob.webp");
                -webkit-mask-image: url("fond_PP_05_mob.webp");
                @include mode("tablet") {
                    mask-image: url("fond_PP_05.webp");
                    -webkit-mask-image: url("fond_PP_05.webp");
                }
            }
            &.bg6 {
                mask-image: url("fond_PP_06_mob.webp");
                -webkit-mask-image: url("fond_PP_06_mob.webp");
                @include mode("tablet") {
                    mask-image: url("fond_PP_06.webp");
                    -webkit-mask-image: url("fond_PP_06.webp");
                }
            }
            &.bg7 {
                mask-image: url("fond_PP_07_mob.webp");
                -webkit-mask-image: url("fond_PP_07_mob.webp");
                @include mode("tablet") {
                    mask-image: url("fond_PP_07.webp");
                    -webkit-mask-image: url("fond_PP_07.webp");
                }
            }
            &.bg8 {
                mask-image: url("fond_PP_08_mob.webp");
                -webkit-mask-image: url("fond_PP_08_mob.webp");
                @include mode("tablet") {
                    mask-image: url("fond_PP_08.webp");
                    -webkit-mask-image: url("fond_PP_08.webp");
                }
            }
        }

        :global(.teamItem2 .title) {
            text-align: center !important;
        }
        .tease {
            width: 100%;
        }
        .info {
            background-color: #fff;
            position: absolute;
            bottom: 0;
            transform: translate(15px, calc(100% - 20px));
            width: 100%;
            text-align: center;
            padding: 0px 5px 5px;
            font-family: "Didact Gothic";
            mask-image: url("trait_mob.webp");
            -webkit-mask-image: url("trait_mob.webp");
            mask-repeat: no-repeat;
            -webkit-mask-repeat: no-repeat;
            mask-position: -20px 0px;
            -webkit-mask-position: -20px 0px;
            mask-size: cover;
            -webkit-mask-size: cover;
            padding: 10px 15px 12px;

            width: 100%;
            margin: auto;
            font-weight: bold;
            visibility: hidden;
            transform: translateY(calc(100% - 25px));

            @include mode("desktop") {
                padding: 5px 15px 12px;
                transform: translate(8px, calc(100% - 20px));
                mask-image: url("trait.svg");
                -webkit-mask-image: url("trait.svg");
            }
        }
        .name {
            width: 100%;
            display: flex;
            justify-content: center;
        }
        .currentName {
            width: 100%;
            display: block;
            background-color: #fff;
            padding: 10px 5px 15px;
            font-family: Impact;
        }
        .role {
            opacity: 0;
            transform: translateY(10px);
            transition: all 0.2s ease 0s;
            font-size: 15px;
            @include mode("desktop") {
                font-size: 18px;
            }
        }
        .roleInfo {
            color: $color-red;
            font-size: 13px;
            opacity: 0;
            transform: translateY(20px);
            transition: all 0.2s ease 0s;
            padding-bottom: 5px;
            @include mode("desktop") {
                font-size: 15px;
            }
        }

        .twitter img {
            width: 16px;
        }

        .twitter {
            opacity: 0;
            transform: translateY(20px);
            transition: all 0.2s ease 0s;
            display: flex;
            justify-content: center;
            column-gap: 10px;
            padding-bottom: 5px;
        }

        &:hover {
            transform: scale(1.05);
            z-index: 3;

            .info {
                opacity: 1;
                visibility: initial;
                .role {
                    transition: all 0.2s ease 0.1s;
                }
                .roleInfo {
                    transition: all 0.2s ease 0.2s;
                }
                .twitter {
                    transition: all 0.2s ease 0.3s;
                }
                .role,
                .roleInfo,
                .twitter {
                    opacity: 1;
                    transform: translateY(0px);
                }
            }
        }
    }
</style>
