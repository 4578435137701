<script>
    import { fullsizeStore } from "../stores/stores";
    import VideoItem from "../common/VideoItem.svelte";

    let fullsizeNode;
    let crossNode;
    let closeNode;

    let fullsizeStoreValue;
    let videoIsLoaded = false;

    fullsizeStore.subscribe((val) => {
        fullsizeStoreValue = val;
    });

    const hide = (e) => {
        let target = e.target;
        if (target.isSameNode(fullsizeNode) || target.isSameNode(crossNode) || target.isSameNode(closeNode)) {
            updateFullsizeStoreValue();
            videoIsLoaded = false;
        }
    };

    const updateFullsizeStoreValue = () => {
        fullsizeStore.update((val) => null);
    };
    // console.log("voici fullsizestore", fullsizeStoreValue);
</script>

<div class="fullsize" bind:this={fullsizeNode} class:on={fullsizeStoreValue && videoIsLoaded} on:click={hide}>
    <div class="cross" bind:this={crossNode} />
    <div class="close" bind:this={closeNode} />

    <div class="container">
        {#if fullsizeStoreValue}
            <VideoItem {...fullsizeStoreValue} onLoad={() => {videoIsLoaded = true}}/>
        {/if}
    </div>
</div>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";
    .fullsize {
        opacity: 0;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 4;
        transition: all 0.2s ease 0s;
        .cross {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            transition: all 0.2s ease 0s;

            cursor: pointer;
            &:hover {
                background-color: rgba(0, 0, 0, 0.1);
            }
        }

        .close {
            width: 60px;
            height: 60px;
            top: 15px;
            right: 15px;
            position: fixed;
            z-index: 1;
            background:url('button_cross_02.webp') no-repeat;
            background-size: contain;
            cursor: pointer;
            transition: all 0.2s ease 0s;
            &:hover {
                transform: scale(1.1);
            }

        }
        &.on {
            opacity: 1;
            pointer-events: visible;

            animation: showNft 0.6s ease 0s forwards;
            animation-timing-function: steps(20);
            @keyframes showNft {
                0% {
                    clip-path: polygon(0px 0px, 0px 10%, 10% 0px);
                }
                100% {
                    clip-path: polygon(0px 0px, 0px 200%, 200% 0px);
                }
            }
        }
        .container {
            & :global(*) {
                width: calc(100%);
                height: 100%;
                max-width: 100%;
                max-height: calc(100vh - 0px);
            }
        }
    }
</style>
