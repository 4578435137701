<script>
    import Title from "../common/Title.svelte";
    import VideoItem from "../common/VideoItem.svelte";

    import { onPlayer } from "../stores/stores";

    const updatePlayerValue = (value = "") => {
        onPlayer.update((n) => value);
    };
</script>

<div class="roadmap" id="roadmap">
    <Title text="ROADMAP" big={true} />

    <div class="videoWrapper">
        <VideoItem
            trigger={() => {
                // let y = +info?.getAttribute("data-y");
                let y = window.scrollY;
                updatePlayerValue({
                    video: "roadmap_hd.mp4",
                    title: "ORDOS Introduction",
                    y: y,
                });
            }}
            withMask={true}
            withLoader={true}
            srcMobile="roadmap_mob.mp4"
            srcTablet="roadmap_hd.mp4"
            srcDesktop="roadmap_hd.mp4"
            cls="200"
        />

        <div
            class="buttons"
            on:click={() => {
                let y = window.scrollY;
                updatePlayerValue({
                    video: `roadmap_hd.mp4`,
                    y: y,
                });
            }}
        >
            <button
                on:click={() => {
                    let y = window.scrollY;

                    updatePlayerValue({
                        video: `roadmap_hd.mp4`,
                        y: y,
                    });
                }}
                aria-label="Fullscreen"
            />
        </div>
    </div>
</div>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";
    .roadmap {
        padding: 30px;
        text-align: center;
        max-width: 1248px;
        margin:auto;
        .videoWrapper{
            position: relative;
        }
        :global(.title){
            text-align: center !important;
        }
        .buttons {
            width: 100%;
            position: absolute;
            bottom: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            // background-color: $color-grey;
            z-index: 1;
            @include mode("desktop") {
                height: 100%;
                justify-content: center;
                // pointer-events: none;
            }

            button {
                border: none;
                background-color: $color-grey;
                background-color: $color-red;
                width: 50px;
                height: 50px;
                margin-bottom: 0;
                padding-bottom: 0;
                // border-radius: 50%;
                transform: translate(50%, 30%);
                background: url("./button_fullscreen_white_mob.webp") no-repeat;
                background-size: contain;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                transform: translateY(calc(100% - 30px));
                transition: all 0.2s ease 0s;
                user-select: none;
                @include mode("tablet") {
                    background: url("./button_fullscreen_white.webp") no-repeat;
                    background-size: contain;
                }
                @include mode("desktop") {
                    transform: scale(1.3);
                    opacity: 0;
                    width: 65px;
                    height: 65px;
                }
            }

            @include mode("desktop") {
                cursor: pointer;
            }
            &:hover button {
                @include mode("desktop") {
                    transform: scale(1);
                    opacity: 1;
                    cursor: pointer;
                    & :global(svg path) {
                        fill: $color-red;
                        fill: white;
                    }
                }
            }
        }
    }
</style>
