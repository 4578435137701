<script>
    import Trailer from "../trailer/Trailer.svelte";

    import { onLimit, skipIntro, stopWizard } from "../stores/stores";

    import Title from "../common/Title.svelte";
    import { onMount } from "svelte";

    let scrollTopValue;
    let limit;
    let disappearIllustration = false;
    let skipIntroValue;
    let stopWizardValue;

    stopWizard.subscribe((val) => {
        stopWizardValue = val;
    });

    skipIntro.subscribe((val) => {
        skipIntroValue = val;
    });

    $: {
        // console.log("tema man", scrollTopValue);
        let nextSection = document.querySelector(".nftWrap");
        if (nextSection) {
            // console.log(nextSection);
            // if(nextSection)
            let top = nextSection.offsetTop;
            if (top - scrollTopValue - 600 < 0) {
                disappearIllustration = true;
            } else {
                disappearIllustration = false;
            }
            // if(offsetTop - )
            // console.log({ topi: nextSection.offsetTop, topi2:getOffset(nextSection) });
        }
    }

    $: {
        if (limit) {
            // let y = getOffset(document.querySelector('.limit')).top;
            let y = document.querySelector(".limit").offsetTop;
            absPosition = y - window.innerHeight;
        }
    }

    let bannerHeight;
    let bannerHeight100;
    let bannerNode;
    let scrollRoad;
    let step = null;
    $: {
        // console.log({ bannerHeight });
        // console.log({ scrollRoad });
        bannerHeight100 = bannerHeight / 2;

        if (scrollRoad < bannerHeight100 + (1 / 3) * bannerHeight100) {
            // console.log("case1");
            step = 0;
        } else if (
            scrollRoad > bannerHeight100 + (1 / 3) * bannerHeight100 &&
            scrollRoad < bannerHeight100 + (2 / 3) * bannerHeight100
        ) {
            step = 1;
            // console.log("case2", scrollRoad);
        } else if (
            scrollRoad >= bannerHeight100 + (2 / 3) * bannerHeight100 &&
            scrollRoad < bannerHeight100 + (3 / 3) * bannerHeight100
        ) {
            step = 2;
            // console.log("case3", scrollRoad);
        } else if (scrollRoad >= bannerHeight100 + (3 / 3) * bannerHeight100) {
            step = 3;
            // console.log("case4", scrollRoad);
        }
    }

    $: {
        // console.log("step ", step);
    }
    let toBottom;
    let lastScroll = null;
    onMount(() => {
        bannerHeight = bannerNode.clientHeight;
        window.addEventListener("resize", () => {
            bannerHeight = bannerNode.clientHeight;
            // console.log({ bannerHeight });
        });
        window.addEventListener("scroll", (e) => {
            // console.log("scroll posi", window.scrollY);
            if (lastScroll !== null) {
                if (lastScroll > window.scrollY) {
                    toBottom = false;
                } else {
                    toBottom = true;
                }
            }
            lastScroll = window.scrollY;
            scrollRoad = window.scrollY + bannerHeight / 2;
        });
    });

    const getOffset = (el) => {
        var _x = 0;
        var _y = 0;
        while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
            _x += el.offsetLeft - el.scrollLeft;
            _y += el.offsetTop - el.scrollTop;
            el = el.offsetParent;
        }
        return { top: _y, left: _x };
    };

    $: {
        // console.log({ lastScroll, toBottom });
    }
    var x = getOffset(document.getElementById("div")).left;
    var y = getOffset(document.getElementById("div")).top;

    onLimit.subscribe((val) => {
        limit = val;
    });

    // const lines = {
    //     line1: { start: "M332,0 L332,0 L332,100 L332,100", end: "M332,0 L-50,0 L0,100 L332,100" },
    //     line2: { start: "M0,0 L0,0 L0,100 L0,100", end: "M0,0 L332,0 L332,120 L0,100" },
    //     line3: { start: "M332,0 L332,0 L332,100 L332,100", end: "M332,0 L0,0 L0,100 L332,100" },
    // };

    // const lines = {
    //     line1: { start: "M360,0 L0,0 L0,50 L360,30 Z", end: "M0,0 L0,0 L0,50 L0,50 Z" },
    //     line2: { start: "M0,50 L360,30 L360,70 L0,70 Z", end: "M360,50 L360,30 L360,70 L360,70 Z" },
    //     line3: { start: "M360,70 L0,50 L0,100 L360,100 Z", end: "M0,50 L0,50 L0,100 L0,100 Z" },
    // };
    const lines = {
        line1: { end: "M332,0 L332,0 L332,100 L332,100", start: "M332,0 L-50,0 L0,100 L332,100" },
        line2: { end: "M0,0 L0,0 L0,100 L0,100", start: "M0,0 L332,0 L332,120 L0,100" },
        line3: { end: "M332,0 L332,0 L332,100 L332,100", start: "M332,0 L0,0 L0,100 L332,100" },
    };

    $: getLine = (line = 3) => {
        let lineKey = "";
        if (toBottom == null || toBottom) {
            if (line == 3) {
                lineKey = step >= 1 ? "end" : "start";
            } else if (line == 2) {
                lineKey = step >= 2 ? "end" : "start";
            } else if (line == 1) {
                lineKey = step >= 3 ? "end" : "start";
            } else {
                lineKey = "start";
            }
        } else {
            if (line == 3) {
                lineKey = step <= 2 ? "start" : step >= 1 ? "end" : "start";
            } else if (line == 2) {
                lineKey = step <= 1 ? "start" : step >= 2 ? "end" : "start";
            } else if (line == 1) {
                lineKey = step <= 0 ? "start" : "end";
            } else {
                lineKey = "start";
            }
        }

        return lines?.[`line${line}`]?.[lineKey];
    };
</script>

<div class={`banner ${step > 0 ? "step" : ""} `} class:end={step == 3} bind:this={bannerNode}>
    <svg class="maskWizard" viewBox="0 0 100 100" preserveAspectRatio="none">
        <clipPath id="myClipBanner1" clipPathUnits="objectBoundingBox" transform="scale(0.003033 0.0116279)">
            <path class="test2" d={getLine(1)} />
        </clipPath>
        <clipPath id="myClipBanner2" clipPathUnits="objectBoundingBox" transform="scale(0.003033 0.0116279)">
            <path class="test3" d={getLine(2)} />
        </clipPath>
        <clipPath id="myClipBanner3" clipPathUnits="objectBoundingBox" transform="scale(0.003033 0.0116279)">
            <path class="test4" d={getLine(3)} />
        </clipPath>
    </svg>
    <div class={`step${step} ${step > 0 ? "step" : ""} banner_content`}>
        <div class="bannerWrap" class:stop={stopWizardValue != null} style={`--y:${stopWizardValue}px`}>
            <div class="banner1" />
            <div class="banner2" />
            <div class="banner3" />
        </div>
        <div class="left" />
        <div
            class="right"
            class:end={step == 3}
            style:--rightY={`-${
                step == 1
                    ? bannerHeight100 - 150
                    : step == 2
                    ? bannerHeight100 + 0
                    : step == 3
                    ? bannerHeight100 / 1
                    : "0"
            }px`}
        >
            <!-- {step} -->
            <!-- <Title text="MEET THE FACTIONS" lined={true} /> -->
            <Trailer />
        </div>
    </div>
</div>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";

    .step {
        path.test2 {
            transition: all 0.6s ease 0s;
            -moz-transition: all 0.6s ease 0s;
        }
        path.test3 {
            transition: all 0.4s ease 0s;
            -moz-transition: all 0.4s ease 0s;
        }
        path.test4 {
            transition: all 0.6s ease 0s;
            -moz-transition: all 0.6s ease 0s;
        }
    }

    path {
        transition-timing-function: steps(10, end) !important;
    }

    .maskWizard {
        position: fixed;
        left: -999%;
    }
    .bannerWrap {
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100vh;
        transition: transform 1s cubic-bezier(0.8, -0.4, 0.73, 0.89) 0s;
        // transform: translateX(-60px);
        transform: translateX(-60px);
        opacity: 0;
        animation: bannerWrapReveal 0.6s ease 0.2s forwards;
        @keyframes bannerWrapReveal {
            0% {
            }
            100% {
                opacity: 1;
                transform: translateX(0px);
            }
        }
        &.skip {
            transform: translate(-50%, 0px);
            .banner1 {
                transition: all 0.2s ease 1s;
                opacity: 0;
            }
        }

        &.stop {
            // position: absolute;
            // top:var(--y, 0px);
            // display: none;

            path.test2 {
                transition: all 0.4s ease 0.6s;
                -moz-transition: all 0.4s ease 0.6s;
            }
            path.test3 {
                transition: all 0.4s ease 0.4s;
                -moz-transition: all 0.4s ease 0.4s;
            }
            path.test4 {
                transition: all 0.4s ease 0s;
                -moz-transition: all 0.4s ease 0s;
            }
        }
    }
    .left {
        display: none;
        @include mode("desktop") {
            display: block;
        }
    }

    .banner_content {
        @include mode("desktop") {
            display: grid;
            grid-template-columns: 40% 60%;
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            clip-path: url(#clipWizard);
        }
    }
    .banner {
        width: 100%;
        // background-color: $color-grey;
        // background: radial-gradient(#ffffff, #a3a2a9);
        position: relative;
        // z-index: 6;
        // display: none;
        display: grid;
        grid-template-columns: 1fr;
        // min-height: 100vh;
        // pointer-events: none;
        @include mode("desktop") {
            // height: 100vh;
            min-height: 100vh;
            min-height: 110vh;
            min-height: 145vh;
            &.end {
                pointer-events: none;
            }
            img {
                width: 100%;
                max-width: 650px;
            }
        }

        .right {
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // padding-top: 150px;
            padding: 30px 30px 0px 30px;
            padding-top: 110px;

            @include mode("desktop") {
                padding-top: 90px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                min-height: 100vh;
                &.end {
                    pointer-events: none;
                    opacity: 0;
                }
            }
        }

        .step .right {
            @include mode("desktop") {
                mask-image: url(vignettage_video.webp) no-repeat cover;
                -webkit-mask-image: url(vignettage_video.webp);
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-size: cover;
                -webkit-mask-position: 0px var(--rightY, 0px);
            }
        }

        .banner1,
        .banner2,
        .banner3 {
            position: fixed;
            left: 0;
            width: 55%;
            width: 40%;
            width: 60%;
            height: 100%;
            width: 90%;
            bottom: 0;
            left: -20%;
            // left: 0%;
            background: url("./magicien_crop.webp") no-repeat;
            pointer-events: none;
            background-size: contain;
            // background-attachment: fixed;
            background-position: var(--x, 0px) bottom;
            background-position: 0px bottom;
            transition: transform 1s ease 0s;
            // clip-path: polygon(0px 0px, 100% 0px, 100% 100%, 0px 100%);
            transform: translateY(1px);
            display: none;
            max-height: calc(100vh - 90px);
            max-width: 2100px;
            @include mode("desktop") {
                width: 60%;
                left: 0%;
                display: flex;
            }
            @include mode("big") {
                width: 40%;
                left: 0%;
            }
        }

        .banner1 {
            mask-image: url("brush_spray_01_mob.webp") no-repeat cover;
            -webkit-mask-image: url("brush_spray_01_mob.webp");
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: cover;
            @include mode("tablet") {
                mask-image: url(brush_spray_01.png) no-repeat cover;
                -webkit-mask-image: url(brush_spray_01.png);
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-size: cover;
            }
            clip-path: url(#myClipBanner1);
        }

        .banner2 {
            mask-image: url("brush_spray_02_mob.webp") no-repeat cover;
            -webkit-mask-image: url("brush_spray_02_mob.webp");
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: cover;
            clip-path: url(#myClipBanner2);
            @include mode("tablet") {
                mask-image: url(brush_spray_02.png) no-repeat cover;
                -webkit-mask-image: url(brush_spray_02.png);
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-size: cover;
            }
        }

        .banner3 {
            mask-image: url("brush_spray_03_mob.webp") no-repeat cover;
            -webkit-mask-image: url("brush_spray_03_mob.webp");
            -webkit-mask-repeat: no-repeat;
            -webkit-mask-size: cover;
            clip-path: url(#myClipBanner3);
            @include mode("tablet") {
                mask-image: url(brush_spray_03.png) no-repeat cover;
                -webkit-mask-image: url(brush_spray_03.png);
                -webkit-mask-repeat: no-repeat;
                -webkit-mask-size: cover;
            }
        }
    }
</style>
