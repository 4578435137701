<script>
    import { onMenu, onGoTo } from "./../stores/stores";

    export let text = "";
    export let comingSoon = false;
    export let goTo = true;
    export let index = 1;
    let onMenuValue;

    const setGoToState = (to = "") => {
        onGoTo.update((n) => to);
    };

    // window.onload = SetTop;

    onMenu.subscribe((val) => {
        onMenuValue = val;
    });

    const toggleMenuState = () => {
        onMenu.update((n) => !n);
    };

    const closeMenuState = () => {
        onMenu.update((n) => false);
    };

    const getDelay = () => index * 0.1 + 0.45;
    const getX = () => (index % 2 > 0 ? 35 : 35);

    const offset = (el) => {
        var rect = el.getBoundingClientRect(),
            scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    };
</script>

<li
    class="navItem"
    class:later={comingSoon}
    data-order={index}
    class:on={onMenuValue}
    style={`--delay:${getDelay()}s;`}
>
    <a
        class:isComing={comingSoon}
        href="#"
        on:click={(e) => {
            e.preventDefault();
            if (goTo) {
                setGoToState(text);
            }
            closeMenuState();
        }}
    >
        <div class="bgNav" data-order={index} />
        {text}

        {#if comingSoon}
            <span class="comingSoon">Coming Soon</span>
        {/if}
    </a>
</li>

<style type="text/scss">
    @import "../rules/colors.scss";
    @import "../rules/mixins.scss";

    .navItem {
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 50px;
        min-height: 60px;
        color: white;
        font-size: 16px;
        font-weight: 600;
        position: relative;
        &[data-order="1"],
        &[data-order="3"],
        &[data-order="5"] {
            a {
                position: relative;
                padding: 10px 20px;
                // color:transparent;
                z-index: 1;
                @include mode("desktop") {
                    padding: 0px;
                }
            }
        }
        &[data-order="2"],
        &[data-order="4"],
        &[data-order="6"] {
            a {
                // color:#8C7A7A !important;
                transform: translateX(-10px);
            }
        }

        &.later {
            pointer-events: none;
        }

        &::before {
            content: "";
            width: 100%;
            height: 1px;
            background-color: #e4e4e4;
            position: absolute;
            left: 0;
            bottom: -7px;
            transform-origin: left;
            transform: rotate(-2deg) scaleX(0);
            // transition:all 0.5s ease 0.4s;
        }

        .bgNav {
            position: absolute;
            width: 100%;
            left: 0;
            // background-color: orange;
            height: 100%;
            pointer-events: none;
            // transform:scaleX(0);
            &[data-order="1"] {
                width: 204px;
                // height: 70px;
                width: 100%;
                height: 100%;
                background: url("./bg_nav_4.webp") no-repeat;
                z-index: -1;
                top: 0;

                // background: url("./bg_nav_1.png") no-repeat;
                background-size: 100% 100%;
                transform-origin: right;
                clip-path: polygon(100% 0px, 100% 0px, 100% 100%, 100% 100%);
            }
            &[data-order="2"] {
                width: 204px;
                height: 70px;
                // background: url("./bg_nav_2.png") no-repeat;
                background-size: 100% 100%;
                left: 5px;
                transform-origin: left;
                clip-path: polygon(0% 0px, 0% 0px, 0% 100%, 0% 100%);
            }
            &[data-order="3"] {
                width: 100%;
                height: 100%;
                // background: url("./bg_nav_3.png") no-repeat;
                background: url("./bg_nav_4.webp") no-repeat;

                background-size: 100% 100%;
                top: 0px;
                z-index: -1;
                // left: 15px;
                transform-origin: right;
                clip-path: polygon(100% 0px, 100% 0px, 100% 100%, 100% 100%);
            }
            &[data-order="4"] {
                width: 204px;
                height: 65px;
                // background: url("./bg_nav_4.png") no-repeat;
                background-size: 100% 100%;
                left: 20px;
                top: -10px;
                transform-origin: left;
                clip-path: polygon(0% 0px, 0% 0px, 0% 100%, 0% 100%);
            }
            &[data-order="5"] {
                width: 100%;
                height: 100%;
                background: url("./bg_nav_4.webp") no-repeat;

                // background: url("./bg_nav_5.png") no-repeat;
                background-size: 100% 100%;
                // left: 11px;
                z-index: -1;
                top: 0px;
                transform-origin: right;
                clip-path: polygon(100% 0px, 100% 0px, 100% 100%, 100% 100%);
            }
            &[data-order="6"] {
                width: 224px;
                height: 80px;
                // background: url("./bg_nav_6.png") no-repeat;
                background-size: 100% 100%;
                transform-origin: left;
                clip-path: polygon(0% 0px, 0% 0px, 0% 100%, 0% 100%);
            }
            @include mode("desktop") {
                display: none;
            }
        }

        a {
            color: $color-grey;
            color: #86949d;
            color: white;
            color: transparent;
            font-weight: normal;
            text-decoration: none;
            font-weight: bold;
            z-index: 1;
            color: rgba(0, 0, 0, 0);
            position: relative;
            img {
                width: 40px;
            }
            // text-shadow: 0px -10px 2px rgba(255, 255, 255, 0);
            // text-shadow: 0px 5px 2px black;

            text-transform: uppercase;
            opacity: 1;

            transform: translateY(var(--x));
            cursor: pointer;
            user-select: none;

            .comingSoon {
                font-size: 15px;
                // opacity: 0.4;
                // margin-left: 15px;
                white-space: nowrap;
                padding: 5px 10px;
                position: absolute;
                left: 0;
                right: 0;
                // top: 40%;
                top: 46%;
                // transform: translate(0%, -50%);
                margin: auto;
                color: black;
                color:$color-red;
                text-align: center;

                // color:#a5b4bd;
                // color: white;
                // display: none;
                opacity: 0;

                @include mode("desktop") {
                    display: block;
                    text-decoration: none;
                    margin-left: 0;
                    opacity: 0;
                    position: absolute;
                    bottom: 0;
                    left: initial;
                    top: initial;
                    transform: translate(-100%, 0px);
                    // background-color: $color-grey;
                    left: 50%;
                    transition: all 0.2s ease 0s;
                    // width: 100%;
                }
            }

            &.isComing {
                text-decoration: line-through;
                // color: #a5b4bd50;
                // color:black;
                @include mode("desktop") {
                    text-decoration: initial;
                    color: #a5b4bd;
                }
            }

            &:hover {
                // text-shadow: 0px 0px 1px rgba(255,255,255,1);
                @include mode("desktop") {
                    &.isComing {
                        text-decoration: line-through;
                    }
                    color: lighten(#a5b4bd, 15%);
                    color: darken(#a5b4bd, 15%);
                    // text-shadow: 0px -2px 2px lighten(rgba(255, 255, 255, 0.1%), 5%);
                    .comingSoon {
                        // right: initial;
                        display: inline-block;
                        // left: 0%;
                        color: $color-red;
                        transform: translate(calc(-100% + 10px), 10px);
                        opacity: 1;
                    }
                }
            }
            @include mode("desktop") {
                transition: all 0.3s ease 0s;

                color: white;
                transform: initial;
                opacity: 1;
                color: #a5b4bd;
                // text-shadow: 0px -2px 2px rgba(255, 255, 255, 0.1%);
                color: #a5b4bd;
                // color:#83939d;
                // text-shadow: 0px 5px 2px black;
                transform: translateY(-3px) !important;
                font-weight: bold;
                // color: #8f9ba2 !important;
            }
        }
        // &.on {
        //     a {
        //         opacity: 1;
        //         transform: translateY(0px);
        //     }
        // }

        @include mode("desktop") {
            // min-width: 100px;
            transform: translateY(2px);
            min-height: 60px;
            font-weight: 400;
            font-family: "Heebo", sans-serif;
            font-weight: bold;
            // font-family: "Anton", sans-serif;
            border-bottom: none;
            &.later {
                pointer-events: initial;
            }
        }

        &.on {
            a {
                color: white;
                transition: all 0.3s ease var(--delay);
                &.isComing {
                    color: rgba(255, 255, 255, 0.7);
                    .bgNav {
                        opacity: 0.6;
                    }
                }
                .comingSoon{
                    transition: all 0.3s ease var(--delay);
                    opacity: 1;
                }

                @include mode("desktop") {
                    color: #8f9ba2;
                    color: #a5b4bd;
                }
            }

            &[data-order="2"],
            &[data-order="4"],
            &[data-order="6"] {
                a {
                    transform: translateX(0px);
                    color: #8c7a7a;
                    @include mode("desktop") {
                        color: #a5b4bd;
                    }
                }
            }

            &::before {
                transition: all 0.4s ease 0.4s;
                transform: rotate(-2deg) scaleX(1);
            }
            .bgNav {
                transform: scale(1);
                transition: all 0.2s ease var(--delay);
                // transition-timing-function: steps(8);
                &[data-order="1"] {
                    clip-path: polygon(0% 0px, 100% 0px, 100% 100%, 0% 100%);
                }
                &[data-order="2"] {
                    clip-path: polygon(0% 0px, 100% 0px, 100% 100%, 0% 100%);
                }

                &[data-order="3"] {
                    clip-path: polygon(0% 0px, 100% 0px, 100% 100%, 0% 100%);
                }
                &[data-order="4"] {
                    clip-path: polygon(0% 0px, 100% 0px, 100% 100%, 0% 100%);
                }
                &[data-order="5"] {
                    clip-path: polygon(0% 0px, 100% 0px, 100% 100%, 0% 100%);
                }
                &[data-order="6"] {
                    clip-path: polygon(0% 0px, 100% 0px, 100% 100%, 0% 100%);
                }
            }
        }
    }
</style>
